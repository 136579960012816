// Here you can add other styles
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700&display=swap');
body {
  font-family: 'Roboto', sans-serif;
  // font-family: 'Poppins', sans-serif;
}
.base_link {
  cursor: pointer;
  background: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
  color: rgba(242, 242, 242, 0.6);
  border-bottom: 1px solid rgba(242, 242, 242, 0.6);
}
.base_link:hover {
  border-bottom: 1px solid transparent;
}
.base_link:focus {
  background: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
  color: rgba(242, 242, 242, 0.6);
  outline: 0px;
}
.lnk_wrap {
  color: rgba(242, 242, 242, 0.6);
  .base_link {
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
    display: block;
    margin-top: 15px;
    color: rgba(242, 242, 242, 0.6);
  }
}
nav a {
  color: #20a8d8;
}
::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1) !important;
}
::-moz-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1) !important;
}
:-ms-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1) !important;
}
::-ms-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1) !important;
}
::placeholder {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 1) !important;
}
// header nav css
.app-header {
  background-color: #007796;
  border-bottom: 0px solid #c8ced3;
  height: 86px;
}
.header-fixed .app-body {
  @media (min-width: 992px) {
    margin-top: 86px;
  }
}

// common classes
.heading-3 {
  font-weight: 500;
  font-size: 23px;
  // line-height: 27px;
  color: #000000;
  margin-bottom: 0rem;
  margin-top: 10px;
}
.heading-4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}
.btn.btn-add {
  font-weight: 700;
  font-size: 14px;
  line-height: 92.58%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eda843;
  background-color: #ffffff;
  border-color: #eda843;
  box-shadow: none;
  width: 110px !important;
  justify-content: center;
  height: 45px;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  cursor: pointer;
  .btn-add-icon {
    padding: 0 5px 0 5px;
  }
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #00647E;
    border-color: #00647E;
  }
}
.btn-add:not(:disabled):not(.disabled):active:focus,
.btn-add:not(:disabled):not(.disabled).active:focus,
.show > .btn-add.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00647E;
}

.btn-add:not(:disabled):not(.disabled):active,
.btn-add:not(:disabled):not(.disabled).active,
.show > .btn-add.dropdown-toggle {
  color: #fff;
  background-color: #00647E;
  border-color: #00647E;
}

// sidebar css
.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #fff;
  background: #00647E;
  .nav-item {
    margin-bottom: 30px;
    .nav-link {
      border-radius: 50px;
      font-size: 0.813rem;
      font-weight: 500;
      line-height: 0.938rem;
      color: #fff;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &.active {
        color: #fff;
        background: #00647E;
        border-radius: 50px;
        height: 40px;
      }
      &:hover {
        color: #fff;
        background: #00647E;
      }
      .nav-icon {
        display: inline-block;
        width: 1.5rem;
        margin: 0 1.4rem 0 0;
        font-size: 1.3rem;
        color: #fff;
        text-align: center;
      }
    }
  }

  .nav {
    margin-top: 34px;
  }
  .sidebar-nav {
    position: relative;
    flex: 1 1;
    width: 200px;
    margin-left: 15px;
  }
}
// .sidebar .sidebar-minimizer::before {
//     background-image: url();
// }

.app-header {
  .navbar-brand {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 86px;
    height: 55px;
    padding: 0;
    margin-right: 0;
    background-color: transparent;
    margin-bottom: 0px;
    img{
      cursor: pointer;
      margin-left: -3px;
      position: absolute;
    }
  }
  .alp_dropdown {
    color: #ffffff;
    background-color: #007796;
    border-color: #007796;
    border-radius: 3rem;
    box-shadow: 0 0 0;
    &:hover,
    &:focus {
      color: #ffffff;
      background-color: #007796;
      border-color: #00647E;
      box-shadow: 0 0 0;
    }
  }
  .alp_dropdown:not(:disabled):not(.disabled):active,
  .alp_dropdown:not(:disabled):not(.disabled).active,
  .show > .alp_dropdown.dropdown-toggle {
    color: #ffffff;
    background-color: #182a5c;
    border-color: #00647E;
    box-shadow: 0 0 0;
  }
  .cl_profile_dropm.dropdown-menu {
      border: 0px solid #c8ced3;
      border-radius: 0;
      background-color: #00647E;
    // min-width: 24rem;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2),
      0px 6px 30px rgba(0, 0, 0, 0.12), 0px 16px 24px rgba(0, 0, 0, 0.14);
      button.dropdown-item {
      font-size: 16px;
      line-height: 24px;
      // min-width: 24rem;
      border-bottom: 0px solid #c8ced3;
      padding: 10px 20px;
      color: #fff;
      svg{
        margin-right: 10px;
        width: 18px;
      }
      img{
        margin-right: 10px;
        width: 18px;
        height: 16px;
      }
      button.dropdown-item:focus, button.dropdown-item:hover, &:hover {
        background-color: #007796!important;
        border: 0px;
        outline: 0px;
      }
    }
  }
}
.header_item_list {
  list-style: none;
  li {
    display: inline-block;
    position: relative;
    margin-left: 26px;
    margin-right: 26px;
    color: white;
    img{
      cursor: pointer;
    }
    .MuiSvgIcon-root{
      cursor: pointer;
    }
    .icons {
      color: white;
      cursor: pointer;
    }
    span.badge.badge-warning.badge-pill {
      position: absolute;
      top: -2px;
      left: -3px;
    }
  }
}
.cl_avtar_wrap {
  display: flex;
  align-items: center;
  .cl_det {
    display: inline-block;
    text-align: left;
    .cl_name {
      color: #f2f2f2;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: block;
    }
    .cl_des {
      color: #f2f2f2;
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      display: block;
    }
  }
  .cl_drop_arrow {
    padding: 0 20px;
    .fa-pro-arr {
      color: #ffffff;
    }
  }
}

span.badge {
  min-width: 1.2rem;
  font-size: 0.6rem;
  line-height: 12px;
  height: 12px;
  color: #fff;
  border-radius: 20rem;
  font-weight: 500;
  padding: 0 5px;
  margin-left: 14px;
  text-align: center;
  float: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

main.main {
  background-color: #ffffff;
}
.main {
  .container-fluid {
    padding: 0;
    &.container_wrap {
      padding-top: 20px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
.card {
  margin-bottom: 0;
  border: 0px solid #c8ced3;
  border-radius: 0;
  margin: 0 0 0 0;
  box-shadow: none;
}

.app-header .alp_dropdown {
  width: 299px;
  height: 40px;
  text-transform: capitalize;
  text-align: left;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content:flex-end;
  position: relative;
  font-weight: 500;
  font-size: 23px;
  line-height: 27px;
  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    margin-top: 7px;
    position: absolute;
    right: 26px;
    top: 9px;
    font-size: 15px;
  }
  .chooseproject_icon {
    padding-right: 18px;
  }
}

ul.my-lft-list {
  margin-top: 20px;
  li {
    height: 40px;
    border-radius: 0px 40px 40px 0px;
    position: relative;
    &:before {
      content: url('../assets/img/accordion-arrow.svg');
      position: absolute;
      left: 1rem;
      top: 0.8rem;
    }
    a {
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      background: #fff;
      color: #5f6368;
      height: 40px;
      padding-left: 30px;
      display: flex;
      align-items: center;
      text-decoration: none;
      &.active {
        background: #e8f0fe;
        color: #007796;
        border-radius: 0px 40px 40px 0px;
      }
      &:hover {
        color: #007796;
        background: #e8f0fe;
        border-radius: 0px 40px 40px 0px;
      }
      .btn-add-icon {
        margin-right: 0.8rem;
      }
    }
  }
}

button.rgt-pop-icon {
  text-decoration: none;
  outline: none;
  display: inline-block;
  margin-right: 2rem;
  float: right;
  background: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
  color: rgba(242, 242, 242, 0.6);
  img {
    display: block;
  }
}

// add_pop-rgt
.add_pop-rgt {
  position: fixed;
  overflow-y: scroll;
  top: 86px;
  right: -15px;
  background-color: #ffffff;
  width: 362px;
  bottom: 0;
  // z-index: 1032;
  z-index: 2;
  padding: 22px 15px;
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.14);
  .MuiInput-underline.Mui-disabled:before{
    border-bottom-style: solid;
  }
  .heading-3 {
    font-size: 20px;
    margin-top: 0px;
  }
  .btn-close-icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 23px 15px 23px 23px;
    cursor: pointer;
  }
  form {
    // .input-field:first-child{
    //     margin-top: 1.25rem;
    // }
    .MuiInput-underline:before {
      border-bottom: 1px solid #949494;
    }
    .input-field {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      &.col label {
        left: 0;
      }
    }
  }
  .tipsText {
    margin: 0px;
    font-size: 12px;
    color: #bdbdbd;
    font-weight: 300;
  }
  .sec_difference {
    padding-top: 24px;
    width: 100%;
    h4 {
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      color: #000000;
      // &.head_bottom_space{
      //     margin-bottom: 1.52rem;
      // }
    }
    .MuiFormGroup-root {
      span.MuiRadio-root {
        color: #2c98f0;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        padding: 9px 9px 9px 9px;
        margin-right: 12px;
      }
      .MuiFormControlLabel-label {
        color: #333;
      }
      .MuiFormControlLabel-root {
        margin-bottom: 0.2rem;
      }
    }
  }
  select {
    font-size: 14px;
    line-height: 16px;
    color: #333333;
    background:#fff url(../assets/img/downArrow.png) no-repeat right center;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    -ms-appearance: none;
         -o-appearance: none;
    appearance:none;
  }
  select::-ms-expand{
    display: none;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .add_pop-rgt {
    right: 0px !important;
  }
}
input[type='text']:not(.browser-default) {
  height: 2.5rem !important;
}

.signin_form {
  form {
    .submit-btn {
      background-color: #007796 !important;
      height: 54px;
      padding-left: 35px;
      padding-right: 35px;
      color: #fff !important;
      width: 100%;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 18px;
      margin-top: 25px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    }
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgba(47, 128, 237, 1);
  }
  input[type='text']:not(.browser-default) {
    height: 3rem !important;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
  }
  input:not([type]),
  input[type='text']:not(.browser-de1fault),
  input[type='password']:not(.browser-default),
  input[type='email']:not(.browser-default),
  input[type='url']:not(.browser-default),
  input[type='time']:not(.browser-default),
  input[type='date']:not(.browser-default),
  input[type='datetime']:not(.browser-default),
  input[type='datetime-local']:not(.browser-default),
  input[type='tel']:not(.browser-default),
  input[type='number']:not(.browser-default),
  input[type='search']:not(.browser-default),
  textarea.materialize-textarea {
    margin-bottom: 0px;
  }
  form {
    input:not([type]):focus:not([readonly]),
    input[type='text']:not(.browser-default):focus:not([readonly]),
    input[type='password']:not(.browser-default):focus:not([readonly]),
    input[type='email']:not(.browser-default):focus:not([readonly]),
    input[type='url']:not(.browser-default):focus:not([readonly]),
    input[type='time']:not(.browser-default):focus:not([readonly]),
    input[type='date']:not(.browser-default):focus:not([readonly]),
    input[type='datetime']:not(.browser-default):focus:not([readonly]),
    input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
    input[type='tel']:not(.browser-default):focus:not([readonly]),
    input[type='number']:not(.browser-default):focus:not([readonly]),
    input[type='search']:not(.browser-default):focus:not([readonly]),
    textarea.materialize-textarea:focus:not([readonly]) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.42);
      box-shadow: 0 1px 0 0 #fff;
    }
    input[type='text']:not(.browser-default):focus:not([readonly]) + label,
    input[type='password']:not(.browser-default):focus:not([readonly]) + label {
      color: rgba(255, 255, 255, 0.87);
    }
    input.valid:not([type]),
    input.valid:not([type]):focus,
    input.valid[type='text']:not(.browser-default),
    input.valid[type='text']:not(.browser-default):focus,
    input.valid[type='password']:not(.browser-default),
    input.valid[type='password']:not(.browser-default):focus,
    input.valid[type='email']:not(.browser-default),
    input.valid[type='email']:not(.browser-default):focus,
    input.valid[type='url']:not(.browser-default),
    input.valid[type='url']:not(.browser-default):focus,
    input.valid[type='time']:not(.browser-default),
    input.valid[type='time']:not(.browser-default):focus,
    input.valid[type='date']:not(.browser-default),
    input.valid[type='date']:not(.browser-default):focus,
    input.valid[type='datetime']:not(.browser-default),
    input.valid[type='datetime']:not(.browser-default):focus,
    input.valid[type='datetime-local']:not(.browser-default),
    input.valid[type='datetime-local']:not(.browser-default):focus,
    input.valid[type='tel']:not(.browser-default),
    input.valid[type='tel']:not(.browser-default):focus,
    input.valid[type='number']:not(.browser-default),
    input.valid[type='number']:not(.browser-default):focus,
    input.valid[type='search']:not(.browser-default),
    input.valid[type='search']:not(.browser-default):focus,
    textarea.materialize-textarea.valid,
    textarea.materialize-textarea.valid:focus,
    .select-wrapper.valid > input.select-dropdown {
      border-bottom: 1px solid #2f80ed;
      box-shadow: 0 1px 0 0 #2f80ed;
    }
  }
  &.form_wrapper {
    width: 326px;
  }
}
input {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #000000;
}

// Input select box
input[type='text']:not(.browser-default):focus:not([readonly]) + label {
  color: rgba(48, 72, 148, 0.87);
}
// input[type=text]:not(.browser-default):focus:not([readonly]){
// 	border-bottom: 1px solid #00647E;
// 	box-shadow: 0 1px 0 0 #00647E
// }
input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #00647E;
  box-shadow: 0 1px 0 0 #00647E;
}
input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type='text']:not(.browser-default),
input.valid[type='text']:not(.browser-default):focus,
input.valid[type='password']:not(.browser-default),
input.valid[type='password']:not(.browser-default):focus,
input.valid[type='email']:not(.browser-default),
input.valid[type='email']:not(.browser-default):focus,
input.valid[type='url']:not(.browser-default),
input.valid[type='url']:not(.browser-default):focus,
input.valid[type='time']:not(.browser-default),
input.valid[type='time']:not(.browser-default):focus,
input.valid[type='date']:not(.browser-default),
input.valid[type='date']:not(.browser-default):focus,
input.valid[type='datetime']:not(.browser-default),
input.valid[type='datetime']:not(.browser-default):focus,
input.valid[type='datetime-local']:not(.browser-default),
input.valid[type='datetime-local']:not(.browser-default):focus,
input.valid[type='tel']:not(.browser-default),
input.valid[type='tel']:not(.browser-default):focus,
input.valid[type='number']:not(.browser-default),
input.valid[type='number']:not(.browser-default):focus,
input.valid[type='search']:not(.browser-default),
input.valid[type='search']:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid #00647E;
  box-shadow: 0 1px 0 0 #00647E;
}

// general form field css
.custom_select {
  .form-control {
    height: calc(2.8em + 0.75rem + 2px);
    color: #333333;
    border: 1px solid #dadce0;
    margin-top: 1.5rem;
  }
}
.custom_input {
  .form-control {
    margin-top: 1.5rem;
    height: calc(2.8em + 0.75rem + 2px);
    color: #333333;
  }
  input[type='text']:not(.browser-default) {
    height: 2rem !important;
  }
  input[type='password']:not(.browser-default) {
    height: 2rem !important;
  }
  .MuiFormControl-marginNormal{
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.editPeople input[type='text']:not(.browser-default) {
  margin-bottom: 0px;
}

.popup_submint_btn {
  background: #00647E!important;
  border-radius: 25px!important;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff!important;
  text-transform: capitalize;
  padding: 0.375rem 1.31rem;
  border: 0px;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #00647E;
    border-color: #00647E;
    box-shadow: 0 0 0 0.2rem #00647E;
  }
}
.popup_submint_btn:not(:disabled):not(.disabled):active:focus,
.popup_submint_btn:not(:disabled):not(.disabled).active:focus,
.show > .popup_submint_btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 72, 148, 0.5);
}
.popup_submint_btn:not(:disabled):not(.disabled):active,
.popup_submint_btn:not(:disabled):not(.disabled).active,
.show > .popup_submint_btn.dropdown-toggle {
  color: #fff;
  background-color: #00647E;
  border-color: #00647E;
}
//hhhhhhhh
.popup_submint_btnT {
  background: #E5E5E5!important;
  border-radius: 25px!important;
  border: 1px solid #EDA843;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center; 
  color: #EDA843!important;
  text-transform: capitalize;
  padding: 0.375rem 1.31rem;
  margin-right: 10px;
  border: 0px;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #E5E5E5;
    border-color: #EDA843;
    box-shadow: 0 0 0 0.2rem rgba(48, 72, 148, 0.5);
  }
}
.popup_submint_btnT:not(:disabled):not(.disabled):active:focus,
.popup_submint_btnT:not(:disabled):not(.disabled).active:focus,
.show > .popup_submint_btnT.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 72, 148, 0.5);
}
.popup_submint_btnT:not(:disabled):not(.disabled):active,
.popup_submint_btnT:not(:disabled):not(.disabled).active,
.show > .popup_submint_btnT.dropdown-toggle {
  color: #fff;
  background-color: #00647E;
  border-color: #00647E;
}



//iiiiiiiiiiiiiiii
.popupSmall {
  padding: 0.375rem 0.7rem;
}
.suggestion_text {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #bdbdbd;
  margin-top: 0.4rem;
  padding-left: 0.2rem;
}

.add_new-item-wrap {
  position: relative;
  z-index: 999;
}
.m-nav {
  position: relative;
  z-index: 0;
  ul {
    padding-left: 0;
    li {
      margin-bottom: 1px;
      ul li {
        margin-top: 1px;
      }
    }
  }
}

.MuiPaper-root {
  color: #5f6368 !important;
}
// .MuiToolbar-root{
//     display: none !important;
// }
.MuiTableCell-root {
  padding: 10px 20px !important;
  text-align: left !important;
  line-height: 0.938rem !important;
  font-weight: 500;
  height: 46px;
  border-bottom: 1px solid #dadce0;
  color: #5f6368 !important;
   .MuiSvgIcon-root{
    cursor: pointer;
  }
}
.MuiTableCell-head{
  height: 41px;
}
.MuiTableCell-alignRight {
  text-align: left !important;
  flex-direction: row !important;
}
.MuiPaper-elevation2 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
.MTableToolbar-root-227 {
  display: none !important;
}
.MuiTableHead-root {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

// left menu accordion css

.m-nav {
  a {
    display: block;
    padding: 0.85rem;
    transition: all 0.25s ease-in;
    background: #e8f0fe;
    color: #007796;
    border-radius: 0px 40px 40px 0px;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    height: 40px;
    padding-left: 30px;
    &:focus {
      color: rgba(255, 255, 255, 0.5);
      background: #030303;
    }
    &:hover {
      color: rgba(255, 255, 255, 0.5);
      background: #030303;
    }
  }
  label {
    display: flex;
    align-items: center;
    padding: 0.55rem 0.85rem 0.55rem 1.15rem;
    transition: all 0.25s ease-in;
    background: #fff;
    color: #5f6368;
    border-radius: 0px 40px 40px 0px;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    min-height: 40px;
    cursor: pointer;
    margin-bottom: 0em;
    &.active {
      background: #e8f0fe;
      color: #007796;
      .btn-add-icon {
        &.hover {
          display: block;
        }
        &.stable {
          display: none;
        }
      }
    }
    &:focus {
      color: rgba(255, 255, 255, 0.5);
      background: #030303;
    }
    &:hover {
      color: #007796;
      background: #e8f0fe;
      .btn-add-icon {
        &.hover {
          display: block;
        }
        &.stable {
          display: none;
        }
      }
    }
    .fa-caret-right {
      color: #5f6368;
      font-size: 17px;
      padding-right: 7px;
    }
    .btn-add-icon {
      position: relative;
      top: -1px;
      left: 1px;
      &.hover {
        display: none;
      }
      &.stable {
        display: block;
      }
    }
    .fa-s {
      width: 13px;
      height: 13px;
    }
    .icon-wrap {
      width: 20px;
      display: inline-block;
      justify-content: flex-end;
      margin-right: 15px;
    }
  }
}
.classA{
height: '200px'}
.classB{
  height: '100px'}
.item-list {
  a {
    padding-left: 2rem;
    background: #ffffff;
    box-shadow: inset 0 0px #373737;
    color: #5f6368;
    text-decoration: none;
    &:focus {
      background: #ffffff;
      color: #007796;
    }
    &:hover {
      background: #ffffff;
      color: #007796;
    }
  }
  label {
    padding-left: 2rem;
    // background: #252525;
    // box-shadow: inset 0 -1px #373737;
    &:focus {
      // background: #131313;
      background: #ffffff;
      color: #007796;
    }
    .btn-add-icon {
      &.hover {
        display: block;
      }
      &.stable {
        display: none;
      }
    }
    &:hover {
      color: #007796;
      background: #e8f0fe;
      .btn-add-icon {
        &.hover {
          display: block;
        }
        &.stable {
          display: none;
        }
      }
    }
  }
  height: 100%;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
.sub-list {
  a {
    padding-left: 4rem;
    background: #353535;
    box-shadow: inset 0 -1px #474747;
  }
}
.sub-item-list {
  label {
    padding-left: 3rem;
    .btn-add-icon {
      &.hover {
        display: block;
      }
      &.stable {
        display: none;
      }
    }
    &:hover {
      color: #007796;
      background: #e8f0fe;
      .btn-add-icon {
        &.hover {
          display: block;
        }
        &.stable {
          display: none;
        }
      }
    }
    &:focus {
      color: #007796;
      background: #e8f0fe;
    }
  }
  a {
    padding-left: 3rem;
    background: #ffffff;
    box-shadow: inset 0 0px #373737;
    color: #5f6368;
    text-decoration: none;
    display: flex;
    align-items: center;
    &:focus {
      color: #007796;
      background: #e8f0fe;
    }
    &:hover {
      color: #007796;
      background: #e8f0fe;
    }
    .icon-wrap {
      width: 35px;
      display: flex;
      justify-content: flex-end;
    }
  }
  height: 100%;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
d {
  .sub-sub-item-list {
    a {
      padding-left: 6rem;
      background: #454545;
      box-shadow: inset 0 -1px #575757;
    }
  }
}
.sub-sub-item-list {
  label {
    padding-left: 6rem;
    background: #454545;
    box-shadow: inset 0 -1px #575757;
    &:focus {
      background: #333333;
    }
    &:hover {
      background: #333333;
    }
  }
  a {
    &:focus {
      background: #333333;
    }
    &:hover {
      background: #333333;
    }
  }
  height: 100%;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
.toggle {
  input[type='checkbox'] {
    &:checked {
      + {
        label {
          + {
            ul {
              max-height: 1000px;
            }
          }
          > span {
            transform: rotate(90deg);
            transform-origin: 25% 55%;
          }
        }
      }
    }
  }
}

// Left menu css
.sidebar-minimized {
  .asst-sidebar-link {
    // padding: 10px 12px;
    span {
      transition: 0.5s all;
      display: none;
      font-size: 13px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      // padding-left: 5px;
      &.icon-wrap {
        width: 30px;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        position: relative;
        .pg_counter {
          width: auto;
          height: 11px;
          background-color: chocolate;
          border-radius: 20px;
          font-weight: bold;
          font-size: 9px;
          line-height: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1px 3px;
          position: absolute;
          top: -3px;
          right: 0px;
        }
        
      }
    }
  }
  // .asst-sidebar-link {
  //     justify-content: center;
  //     padding: 10px 10px;
  // }
}
.sidebar-m {
  background: #007796;
  max-width: 234px;
  color: #fafcfd;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.asst-sidebar-header {
  height: 69px;
  border-bottom: 1px solid transparent;
  padding: 0 17px;
}
.asst-content-header {
  height: 69px;
  border-bottom: 1px solid transparent;
  padding: 0 17px;
  border-color: #dae0e3;
  padding: 0 45px 0 25px;
  .content-header-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #7d868c;
    display: inline-flex;
    height: 100%;
    align-items: center;
    margin-bottom: 0;
    position: relative;
    padding-right: 25px;
    &:not(:first-child) {
      margin-left: 25px;
    }
    &::before {
      content: '';
      position: absolute;
      height: 70%;
      right: 0;
      right: 0;
      border-right: 1px solid #ebf5fa;
    }
  }
  .content-header-heading.active {
    color: #149cd7;
  }
}
.asst-sidebar-logo {
  padding-right: 20px;
  width: 184px;
  .asst-sidebar-logo-img {
    width: 184px;
  }
}
.asst-sidebar-icon {
  height: 20px;
}
.asst-sidebar-link-list {
  list-style: none;
  padding: 0;
  margin-top: 34px;
  padding: 0 14px;
  &:not(.browser-default) {
    padding-left: 14px;
  }
  li {
    margin-bottom: 37px;
  }
}
.asst-sidebar-link {
  display: flex;
  align-items: center;
  padding: 10px 35px 10px 12px;
  width: 100%;
  transition: 0.5s all;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #fafcfd !important;
  text-decoration: none;
  position: relative;
  span {
    padding-left: 0;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 15px;
    color: #f2f2f2;
    text-decoration: none;
    &.icon-wrap {
      width: 30px;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      position: relative;
      .pg_counter {
        width: auto;
        height: 11px;
        background-color: chocolate;
        border-radius: 20px;
        font-weight: bold;
        font-size: 9px;
        line-height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 5px;
        position: absolute;
        top: -3px;
        right: 0px;
      }
      .greenStatus{
        background: #27AE60;
        border: 2px solid #007796;
        box-sizing: border-box;
        border-radius: 50%;
        height: 11px;
        width: 11px;
        position: absolute;
        top: -3px;
        right: 3px;
      }
      .redStatus{
        background: #ff0000;
        border: 2px solid #007796;
        box-sizing: border-box;
        border-radius: 50%;
        height: 11px;
        width: 11px;
        position: absolute;
        top: -3px;
        right: 3px;
      }
    }
  }
  &:hover {
    background: #007796;
    border-radius: 50px;
    transition: 0.5s all;
    text-decoration: none;
    img {
      filter: brightness(30.5);
    }
  }
  &.active {
    background: #007796;
    border-radius: 50px;
    transition: 0.5s all;
    img {
      filter: brightness(30.5);
    }
  }
}
// suggest button css
.MuiChip-clickable {
  background-color: #fff;
  border: 1px solid #f2994a;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.sidebar .sidebar-minimizer::before {
  background-image: url(../assets/img/Rewind.svg);
  background-size: 20px;
}
.sidebar .sidebar-minimizer:hover::before {
  background-image: url(../assets/img/Rewind.svg);
  background-size: 20px;
}
.sidebar .sidebar-minimizer {
  flex: 0 0 81px;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  width: 234px;
  height: 50px;
  background: #00647E!important;
}

// chat component css
.alp_chat-wrap {
  .alp_chat-item {
    margin-top: 54px;

    &.alp_chat-self {
      display: flex;
      align-items: flex-start;
      margin-bottom: 54px;

      .alp_chat-avatar.alp_chat-offline {
        display: block;
        .alp_chat-avatar {
          border-radius: 50%;
        }
      }
      .alp_chat-body {
        max-width: 560px;
        display: block;
        //padding-left: 13px;
        .alp_chat-header {
          display: block;
          .alp_chat-name {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: rgba(130, 130, 130, 0.9);
            padding-bottom: 5px;
            display: block;
          }
        }
        .alp_chat-message {
          background: #00647E;
          border-radius: 0px 14px 14px 14px;
          padding: 13px;
          color: #ffff;
          font-weight: normal;
          font-size: 17px;
          line-height: 20px;
          word-break: break-all;
        }
      }
    }
    &.alp_chat-from {
      display: flex;
      align-items: flex-start;
      margin-bottom: 54px;
      justify-content: flex-end;
      // .alp_chat-avatar.alp_chat-offline {
      //     display: block;
      //     .alp_chat-avatar {
      //         border-radius: 50%;
      //     }
      // }
      .alp_chat-body {
        max-width: 560px;
        display: block;
        padding-left: 13px;
        padding-right: 35px;
        .alp_chat-header {
          display: block;
          .alp_chat-name {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: rgba(130, 130, 130, 0.9);
            padding-bottom: 5px;
            display: block;
            text-align: right;
          }
        }
        .alp_chat-message {
          background: #f2f2f2;
          border-radius: 14px 0px 14px 14px;
          padding: 13px;
          color: #080808;
          font-weight: normal;
          font-size: 17px;
          line-height: 20px;
          word-break: break-word;
          &.alp_chat-just {
            border-radius: 14px 14px 0px 14px;
            margin-bottom: 3px;
          }
        }
      }
    }
  }
  .alp_chat-inputbox {
    display: flex;
    align-items: center;
    margin-top: 5px;
    textarea[type='text'] {
      &.alp_chat-input {
        background-color: #e8f0fe;
        border-radius: 40px;
        border-bottom: 0px solid #9e9e9e;
        height: auto;
        margin-bottom: 0;
        margin-right: 10px;
        padding: 20px 30px;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #828282;
        &:focus {
          box-shadow: 0 0px 0 0 #00647E;
        }
      }
      &::-webkit-input-placeholder {
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #828282 !important;
      }
      &::-ms-input-placeholder {
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #828282 !important;
      }
      &:-ms-input-placeholder {
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #828282 !important;
      }
      &::-moz-placeholder {
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #828282 !important;
      }
      &:-moz-placeholder {
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #828282 !important;
      }
      &::placeholder {
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        color: #828282 !important;
      }
    }
    .alp_chat-attachicon {
      border-radius: 40px;
      width: 67px;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin: 0px;
      padding: 0px;
    }
  }
}
.chatInput {
  position: fixed;
  bottom: -50px;
  width: 100%;
  flex: 0 0 67%;
  max-width: 67%;
    right: 15px;
  width: -webkit-fill-available;
  // bottom:23px;
  margin-left: 10px;
  margin-right: 30px;
  // width: 66%;
margin-bottom: 100px;
}
.ifWraper {
  margin: 0px;
  padding: 0px;
}
.ifWraper .MuiFormControl-root {
  width: 100%;
}

.roate {
  transform: rotate(90deg);
}
.largeSpace {
  // margin-top: 150px;
  margin-top: 10px;
}
.moveIcon {
  transform: rotate(90deg);
  margin-top: -20px;
  color: #828282;
}

// .app-header .navbar-brand {
//   width: 89px;
// }

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .sidebar-minimizer {
    background: #00647E!important;
  }
  .brand-minimized .app-header .navbar-brand {
    width: 86px;
  }
  .sidebar-minimized .sidebar .nav-item {
    width: 60px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .sidebar-minimized .sidebar .nav-item .nav-link {
    display: block;
    padding: 0.75rem 1.2rem;
    height: 40px;
  }
  .sidebar-minimized .sidebar .sidebar-nav {
    overflow: visible;
    width: 86px;
    margin-left: 12px;
  }
  .sidebar-minimized .sidebar .nav {
    width: 60px;
  }
  .sidebar-fixed .sidebar {
    width: 234px;
  }
  .sidebar-minimized.sidebar-fixed .sidebar {
    width: 86px;
  }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #00647E;
    display: flex;
  }
  .sidebar-minimized .sidebar .sidebar-minimizer {
    position: fixed;
    bottom: 0;
    width: 86px;
    height: 50px;
    background-color: #24282c;
  }
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 234px;
  }
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main,
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed.sidebar-minimized .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 86px;
  }
}

// .asst-left-sec{
//     position: relative;
//     overflow-x: hidden;
//     overflow-y: auto;
// }
.asst-sidebar-link div.pg_counter {
  position: absolute;
  right: 15px;
  font-size: 13px;
  font-weight: 400;
}
.sidebar-minimized .asst-sidebar-link div.pg_counter {
  position: absolute;
  // left: 25px;
  font-size: 9px;
  top: 7px;
  background: #eb5757;
  border-radius: 10px;
  min-width:11px;
  // height: 11px;
  line-height: 9px;
  text-align: center;
  font-weight: 400;
  padding: 1px 3px;
}
.sidebar-minimized .asst-sidebar-link div.pg_counter_no_late {
  position: absolute;
  // left: 25px;
  font-size: 9px;
  top: 7px;
  background: #2D9CDB;
  border-radius: 10px;
  min-width:11px;
  // height: 11px;
  line-height: 9px;
  text-align: center;
  font-weight: 400;
  padding: 1px 3px;
  right: 15px;
}
.pg_counter_label{
  font-size: 13px;
  font-weight: 400;
}
.taskMy{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.asst-sidebar-link-list li:last-child {
  margin-bottom: 140px;
}
.MuiTableCell-head {
  font-weight: bold;
}
.MuiTableCell-body {
  font-weight: 500;
}

/*=============================================================*/
.add_pop-rgt .custom_select .form-control.projects {
  background: url(../assets/img/chooseProject.svg) no-repeat 15px;
  padding-left: 40px;
}

.add_pop-rgt .custom_select .form-control.companies {
  background: url(../assets/img/ChooseCompany.svg) no-repeat 15px;
  padding-left: 40px;
}

.add_pop-rgt .custom_select .form-control.documents {
  background: url(../assets/img/Ducumets.svg) no-repeat 15px;
  padding-left: 40px;
}
.add_pop-rgt .custom_select .form-control.organisation {
  background: url(../assets/img/chooseOrgenisation.svg) no-repeat 15px;
  padding-left: 40px;
}
.projectTable td:first-child {
  background: url(../assets/img/PortfolioFilled.svg) no-repeat 15px;
  padding-left: 40px;
}
/*=============pop over style================*/
.Popover {
  position: absolute;
  z-index: 99;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 0 10px #666;
  border-radius: 4px;
  .closePopup {
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    svg {
      fill: rgba(0, 0, 0, 0.54);
      font-size: 20px;
    }
  }
}
.Popover ul {
  padding-top: 0px;
  padding-bottom: 0px;
}
.Popover .MuiMenuItem-root {
  height: 40px;
  color: #333333;
  font-size: 16px;
}
.addBtnPos {
  top: 35px;
  left: 75px;
}
.addBtnPosition {
  //  top:35px;
  // left: 75px;
}
.rgtClickPopuP {
  // top:50%;
  // left:50%;
  transform: translate(-100%, -80%);
}
.renamePosition {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  box-shadow: 5px 8px 15px #ccc;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 21px;
  .btn-primary {
    background: #00647E;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.renamePosition .MuiFormControl-root {
  margin-bottom: 15px;
}
.renamePosition input[type='text']:not(.browser-default) {
  margin-bottom: 0px;
  width: 400px;
  height: 2rem !important;
}
.renamePosition input[type='text']:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
}
.renamePosition .MuiFormLabel-root {
  color: #007796;
}
.renamePosition button {
  border-radius: 100px;
  height: 40px;
}
.icontype {
  margin-right: 20px;
}
.icontypeSm {
  margin-right: 5px;
}
.linkBtn {
  height: 35px;
  line-height: 35px;
  font-style: italic;
}
.peopleIcon {
  height: 0.75em !important;
  width: 0.75em !important;
  color: #bdbdbd;
}
/*==============================*/
.logoLogin {
  background: url(../assets/img/brand/signal.png) no-repeat top left;
  height: 23px;
  width: 40px;
  position: absolute;
  z-index: 1;
  left: 40px;
  top: 47px;
}
.dropZoneWrap div {
  border: 1px dashed #ccc;
  padding: 10px;
  border-radius: 10px;
  min-height: 100px;
}
.dropZoneWrapDrop div {
  border: 1px dashed #ccc;
  padding: 10px;
  border-radius: 10px;
  min-height: 300px;
}
.uploadList:hover {
  background: rgba(0, 0, 0, 0.04);
}
// .brand-minimized .app-header .navbar-brand .navbar-brand-minimized:hover{
//     background: rgba(48, 72, 148, 0.5);
//     border-radius: 50px;
//     transition: 0.5s all;
//     padding: 20px;
// }
.editPlace {
  font-size: 18px;
  margin-left: 4px;
  font-weight: bold;
}
.chatTitle {
  h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    font-size: 20px;
    color: #333;
  }
  img{
    cursor: pointer;
  }
}
.chatLeft {
  margin: 0px;
  padding: 0px;
  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }
  li {
    margin-top: 1px;
    .MuiListItem-button:hover {
      background: none;
    }
    .MuiListItem-secondaryAction {
      padding: 1px;
      border-radius: 40px;
      margin: 10px 0px;
    }
    .MuiListItem-secondaryAction.active,
    .MuiListItem-secondaryAction:hover {
      background-color: #e8f0fe;
    }
    .MuiListItemSecondaryAction-root {
      font-size: 9px;
      line-height: 11px;
      text-transform: uppercase;
      color: #c4c4c4;
    }
    .MuiListItemText-primary {
      font-size: 13px;
      color: #333;
      font-weight: 500;
    }
    .MuiListItemAvatar-root {
      position: relative;
      span {
        position: absolute;
        z-index: 1;
        right: 15px;
        border-radius: 6px;
        font-size: 9px;
        background-color: #eda843;
        color: #fff;
        min-width: 15px;
        text-align: center;
      }
      span.online {
        background: #00ff99;
      }
      span.offline {
        background-color: #eda843;
      }
      span.status {
        background: green;
        top: 25px;
        position: absolute;
        right: 15px;
        border: 1px solid #fff;
        height: 11px;
        width: 11px;
        font-size: 0px;
        min-width: 10px;
        border-radius: 19px;
      }
    }
  }
}

// large right popup for file preview
.rightPreview {
  position: fixed;
  overflow-y: scroll;
  top: 87px;
  right: -15px;
  background-color: #ffffff;
  width: 768px;
  bottom: 0;
  z-index: 1032;
  padding: 22px 15px;
  box-shadow: -2px 0 2px rgba(0, 0, 0, 0.14);
  .heading-3 {
    font-size: 20px;
    margin-top: 0px;
  }
  .btn-close-icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 23px 15px 23px 23px;
    cursor: pointer;
  }
  .filePreview {
    height: 625px;
    width: 100%;
    border: 1px solid #ccc;
  }
}

.titleBreadCrumb {
  nav {
    box-shadow: none;
    background-color: none;
    height: auto;
    .breadcrumb {
      border: 0px;
      margin-bottom: 0px;
      padding: 0px;
      li {
        font-weight: normal;
        font-size: 23px;
        line-height: 27px;
        color: #000000;
        margin-bottom: 0rem;
        margin-top: 10px;
        cursor: pointer;
        a {
          color: #000;
        }
        button{
          background: transparent;
          border: none;
          margin: 0px;
          padding: 0px;
          color: #000000;
        }
      }
      li:last-child {
        cursor: auto;
      }
    }
  }
}

.react-trello-board {
  background-color: rgba(0, 0, 0, 0.2);
}

.react-trello-board > div {
  width: 100%;
}
.taskWraper {
  margin: 0px;
  padding: 0px;
  font-family: Roboto;
  font-size: 17px;
  color: #828282;
  line-height: 20px;
  .PrivateSwitchBase-root-15 {
    padding: 0px;
    margin-right: 20px;
  }
  .badgeBtn {
    display: inline-block;
    border: 1px solid #828282;
    background: #fff;
    box-sizing: border-box;
    border-radius: 110px;
    padding: 2px 15px;
    color: #5f6368;
    font-size: 13px;
    z-index: 2;
    min-width: 110px;
    text-align: center;
  }
  .restoreBtn {
    display: inline-block;
    border: 1px solid #007796;
    box-sizing: border-box;
    border-radius: 110px;
    padding: 2px 15px;
    color: #007796;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    text-transform: capitalize;
    margin-top: -3px;
  }
  .restoreBtn:hover {
    background: #007796;
    color: #fff;
  }
  .taskTitle {
    font-style: normal;
    font-weight: 500;
    color: #5f6368;
    max-width: 100%;
  }
  .ttopacity{
    opacity: 0.2;
  }
  .time_clr{
    color: #2d9cdb;
    font-size: 15px;
    height: 45px;
  }
  .badge_dis{
    display: inline-block;
    border: 1px solid #828282;
    background: #fff;
    box-sizing: border-box;
    border-radius: 110px;
    padding: 2px 15px;
    color: #5f6368;
    font-size: 13px;
    z-index: 2;
  }
  .doc {
  }
  .timeframe {
    color: #2d9cdb;
    font-size: 13px;
  }
  .project_date{
    font-size: 13px;
    color: #828282;
  }
  .end_date{
    font-weight: 600;
  }
  .end_date time{
    font-weight: 400;
  }
  .date_panel{
    margin-top: 13px;
    // align-items: center;
    // justify-content: space-between;
  }
  .end_date span{
    font-weight: 400!important;
  }
  // .wrap_bottom{
  //   // display: flex;
  //   // column-gap: 20px;
  //   align-items: center;
  //   margin: 0;
  //   position: absolute;
  //   right: 30px;
  // }
  // .wrap_top{
  //   width: 50%;
  // }
  .project_date span{
    font-weight: 600;
  }
  .right_date{
    float: right;
    // margin-right: 15px;
  }
  .timeframe span{
    font-weight: 700;
  }
}
.switchWrap [type='checkbox'] + span:not(.lever):before,
[type='checkbox']:not(.filled-in) + span:not(.lever):after {
  width: 12px;
  height: 12px;
  z-index: 0;
  border: 0px solid #5a5a5a;
}
.switchWrap [type='checkbox'] + span:not(.lever) {
  height: 12px !important;
  padding-left: 12px !important;
}
.addTaskbtn {
  font-family: Roboto;
  font-size: 14px;
  color: #eda843 !important;
  margin-left: 20px;
  &:hover{
    svg{
    background: #EDA843;
    color: #fff;
    border-radius: 30px;
    }
  }
}

.btn.btn-save {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 14px;
  align-items: center;
  text-align: center;
  color: #00647E;
  background-color: #ffffff;
  border-color: #00647E;
  box-shadow: none;
  width: 174px;
  height: 45px;

  text-transform: capitalize;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #00647E;
    border-color: #00647E;
  }
}
.btn-save:not(:disabled):not(.disabled):active:focus,
.btn-save:not(:disabled):not(.disabled).active:focus,
.show > .btn-save.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00647E;
}

.btn-save:not(:disabled):not(.disabled):active,
.btn-save:not(:disabled):not(.disabled).active,
.show > .btn-save.dropdown-toggle {
  color: #fff;
  background-color: #00647E;
  border-color: #00647E;
}

.tskTtl {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
  padding: 10px 30px 10px 15px;
  span {
    font-size: 23px;
    line-height: 27px;
    color: #c4c4c4;
  }
}
.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
  background: none !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.overLay {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.24);
}
.confirmWraper {
  background: #fff;
  box-shadow: 0 0 10px #666;
  position: fixed;
  z-index: 1100;
  width: 420px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  border-radius: 4px;
  .confirmTitle {
    font-size: 18px;
    padding: 20px 30px;
    background-color: #f1f1f1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #a5a5a5;
  }
  .confirmMessage {
    padding: 20px 30px;
    font-size: 15px;
  }
  .txtarea-custom textarea:focus-visible{
    border: 1px solid #00647E;
    box-shadow: 0 1px 0 0 #00647E;
    outline:none;
    border-bottom: none;
  }
  .txtarea-custom textarea:focus{
    border: 1px solid #00647E;
    box-shadow: 0 1px 0 0 #00647E;
    outline:none;
    border-bottom: none;
  }
  .confirmAction {
    font-size: 12px;
    border-top: 1px solid #d7d7d7;
    padding: 20px 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: right;
    .btn {
      border-radius: 20px;
      display: inline-block;
      padding: 7px;
      margin-left: 10px;
      height: 37px;
    }
  }
}
.formControlWrap {
  margin: 20px 0px;
}
.formControlWrap .MuiFormControl-root {
  width: 100%;
}
.formControlWrap input:not([type]),
.formControlWrap input[type='text']:not(.browser-default),
.formControlWrap input[type='password']:not(.browser-default),
.formControlWrap input[type='email']:not(.browser-default),
.formControlWrap input[type='url']:not(.browser-default),
.formControlWrap input[type='time']:not(.browser-default),
.formControlWrap input[type='date']:not(.browser-default),
.formControlWrap input[type='datetime']:not(.browser-default),
.formControlWrap input[type='datetime-local']:not(.browser-default),
.formControlWrap input[type='tel']:not(.browser-default),
.formControlWrap input[type='number']:not(.browser-default),
.formControlWrap input[type='search']:not(.browser-default),
.formControlWrap textarea.materialize-textarea {
  margin-bottom: 0px;
}
.formControlWrap p {
  margin-top: 0;
  margin-bottom: 0.6rem;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.85em;
}

.overlayWrap {
  position: fixed;
  z-index: 99;
  background: rgba(0, 0, 0, 0.42);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.overlayWrap .loader {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.previewControlWrap {
  position: static;
  width: 100%;
  border: 5px solid #ccc;
}

// Dashboard table style

.previewControlWrap img,
.previewControlWrap .photo {
  width: 100%;
}
tfoot.MuiTableFooter-root tr {
  border-bottom: 0px !important;
}
tfoot.MuiTableFooter-root .MuiTableCell-root {
  border-bottom: 0px !important;
  padding: 0px 20px !important;
}
thead.MuiTableHead-root .MuiTableCell-head {
  font-weight: bold;
}
@-moz-document url-prefix() {
  thead.MuiTableHead-root .MuiTableCell-head {
    border-bottom: 2px solid #dadce0;
  }
}
.taskpopup {
  width: 434px;
  .heading-3{
    margin-bottom: 1rem;
  }
  .MuiInputLabel-shrink {
    color: #00647E;
    white-space: nowrap;
  }
  .MuiFormLabel-root{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5F6368;
  }
}

.enlargetaskpopup {
  width: 938px !important;
  .heading-3{
    margin-bottom: 1rem;
  }
  .MuiInputLabel-shrink {
    color: #00647E;
  }
}
.editerTask {
  .ql-toolbar.ql-snow {
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    
  }
  .ql-container.ql-snow {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    min-height: 200px;
  }
  .ql-toolbar.ql-snow .ql-formats{
    margin-right: 5px;
  }
  .ql-editor{
    min-height: 200px;
  }
}

.editerReadOnly {
  .ql-toolbar.ql-snow {
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
   // border: 1px solid rgba(0, 0, 0, 0.42);
    
  }
  
  .ql-container.ql-snow {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none
   // min-height: 200px;
  }
  .ql-toolbar.ql-snow .ql-formats{
    margin-right: 5px;
  }
  .ql-editor{
   padding: 3px 3px;
  }
}
.settingWrap {
  background: #e8f0fe;
  border-radius: 4px;
  padding: 10px 0px 15px 0px;
  .custom_select{
    .form-control{
      color: #333333;
      border: 0px;
      height: auto;
      background: transparent;
      padding: 5px 0px;
      &:focus{
        outline: none;
        box-shadow: none;
      }
    }
  }
}
.highlightedDay {
  color: white;
  background-color: #00647E;
}

.taskcalWraper {
  background: #fff;
  box-shadow: 0 0 10px #666;
  position: fixed;
  z-index: 100;
  width: 420px;
  top: 50%;
  // left: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  .title {
    font-size: 17px;
    padding: 20px 30px;
    background-color: #f1f1f1;
    border-bottom: 1px solid #bdbdbd;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .content {
    padding: 10px 0px;
    font-size: 15px;
    .marTB20 {
      margin: 20px 0px;
    }
    .selectBox {
      background-color: #f1f3f4;
      min-width: 70px;
      line-height: 38px;
      legend {
        max-width: 0px;
        transition: none;
      }
    }
    .countup {
      background-color: #f1f3f4;
      border-radius: 5px;
      padding: 9px 10px;
      display: inline-flex;
      margin-bottom: 10px;
      font-size: 15px;
      input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea{
        margin-bottom: 0px;
        height: 20px;
        border-bottom: 0px;
      }
    }
    .MuiOutlinedInput-input {
      padding: 0px 30px 0px 10px;
    }
    .MuiOutlinedInput-notchedOutline{
      border: 0px!important;
    }
    .daysName {
      div {
        display: inline-flex;
        margin-right: 5px;
      }
      .MuiAvatar-root {
        background-color: #f1f3f4;
        color: #666;
        height: 30px;
        width: 30px;
        font-size: 1rem;
      }
      .MuiAvatar-root:hover,
      .MuiAvatar-root:active,
      .MuiAvatar-root.active {
        background-color: #00647E;
        color: #fff;
      }
      .endsWrap {
        margin-bottom: 1px !important;
        height: 30px !important;
      }
      .MuiFormControlLabel-root {
        margin-bottom: 0px;
        height: 30px;
      }
    }
    .fldWrap {
      background-color: #f1f3f4;
      border-radius: 5px;
      padding: 0px 3px 0px 10px;
      display: inline-flex;
      margin-top: 71px;
      margin-bottom: 10px;
      font-size: 15px;
      width: 130px;
      .MuiFormControl-marginNormal{
        margin-top: 0px;
        margin-bottom: 0px;
      }
      input:not([type]):disabled, input:not([type])[readonly="readonly"], input[type=text]:not(.browser-default):disabled, input[type=text]:not(.browser-default)[readonly="readonly"], input[type=password]:not(.browser-default):disabled, input[type=password]:not(.browser-default)[readonly="readonly"], input[type=email]:not(.browser-default):disabled, input[type=email]:not(.browser-default)[readonly="readonly"], input[type=url]:not(.browser-default):disabled, input[type=url]:not(.browser-default)[readonly="readonly"], input[type=time]:not(.browser-default):disabled, input[type=time]:not(.browser-default)[readonly="readonly"], input[type=date]:not(.browser-default):disabled, input[type=date]:not(.browser-default)[readonly="readonly"], input[type=datetime]:not(.browser-default):disabled, input[type=datetime]:not(.browser-default)[readonly="readonly"], input[type=datetime-local]:not(.browser-default):disabled, input[type=datetime-local]:not(.browser-default)[readonly="readonly"], input[type=tel]:not(.browser-default):disabled, input[type=tel]:not(.browser-default)[readonly="readonly"], input[type=number]:not(.browser-default):disabled, input[type=number]:not(.browser-default)[readonly="readonly"], input[type=search]:not(.browser-default):disabled, input[type=search]:not(.browser-default)[readonly="readonly"], textarea.materialize-textarea:disabled, textarea.materialize-textarea[readonly="readonly"]{
        margin-bottom: 0px;
        border-bottom: 0px;
      }
      .MuiInput-underline:before{
        border-bottom: 0px;
      }
      input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea{
        margin-bottom: 0px;
        border-bottom: 0px;
        box-shadow: none;
        color: #9aa0a6;
        font-size: 14px;
      }
      .MuiIconButton-root{
        padding: 5px;
      }
      label {
        margin-bottom: 0px;
        color: #9aa0a6;
      }
    }
    .fldWrapRange {
      background-color: #f1f3f4;
      border-radius: 5px;
      padding: 8px 10px;
      display: inline-flex;
      columns: #9aa0a6;
      input[type='number'] {
        border: 0px;
        margin-bottom: 0px;
        height: 20px;
        color: #9aa0a6;
        width: 70px;
      }
      input[type='number']:focus {
        border: 0px;
        margin-bottom: 0px;
      }
      span {
        color: #9aa0a6;
      }
    }
  }
  .footer {
    font-size: 12px;
    border-top: 1px solid #bdbdbd;
    padding: 15px 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: right;
    .btn {
      border-radius: 20px;
      background: #00647E;
      display: inline-block;
      padding: 7px 15px;
      margin-left: 10px;
      height: 37px;
      color: #fff;
      text-transform: capitalize;
    }
    .btn-light{
      float: left;
      background-color: #F4F4F4;
      border: 1px solid #DCDCDC;
      box-shadow: none;
      color: #4F4F4F;
    }
  }
}
.customDatePicker {
  margin: 0px;
  padding: 0px;
}
.PopoverSaveTemp {
  position: absolute;
  z-index: 99;
  width: 300px;
  background-color: #f1f1f1;
  border-radius: 4px;
  box-shadow: 5px 8px 15px #ccc;
  padding: 10px;
  .btnWrap{
    background: #007796;
    padding: 5px;
    color: #fff;
    width: 35x;
    height: 35px;
    float: left;
    cursor: pointer;
  }
  .imputWrp{
    float: left;
    width: 247px;
    input[type="text"]{
      background: #fff;
      height: 35px!important;
      margin-bottom: 0px;
      border-bottom: 0px;
      padding: 0px 10px;
      color: rgba(0, 0, 0, 0.42);
    }
    input[type="text"]::placeholder{
      color: rgba(0, 0, 0, 0.42);
    }
    input:not([type]):focus:not([readonly]), input[type='text']:not(.browser-default):focus:not([readonly]), input[type='password']:not(.browser-default):focus:not([readonly]), input[type='email']:not(.browser-default):focus:not([readonly]), input[type='url']:not(.browser-default):focus:not([readonly]), input[type='time']:not(.browser-default):focus:not([readonly]), input[type='date']:not(.browser-default):focus:not([readonly]), input[type='datetime']:not(.browser-default):focus:not([readonly]), input[type='datetime-local']:not(.browser-default):focus:not([readonly]), input[type='tel']:not(.browser-default):focus:not([readonly]), input[type='number']:not(.browser-default):focus:not([readonly]), input[type='search']:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
      border-bottom: 0px solid #00647E;
      box-shadow: none;
    }
  }
}
.myEditIcon{
  margin-right: 2px;
  right: 2px;
  position: absolute;
}
.myDeleteIcon{
  margin-right: 2px;
  z-index: 1;
  right: 0px;
  position: absolute;
  .MuiSvgIcon-root{
    font-size: 17px;
  }
}
.myDeletIconFirst{
  margin-right: 30px;

}
.myDeletIconFirst span{
  color: #EDA843;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px){
  .sidebar {
    width: 86px;
  }
  html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 86px;
}
.app-body{
  margin-top: 84px;
}
.myProjects{
  left: 86px;
  position: absolute;
}
.app-header .navbar-brand{
  left: 0px;
  margin-left: 30px;
  width: 30px;
  justify-content: flex-start;
  overflow: hidden;
  margin-top: 14px;
  img{
    margin-left: 0px;
  }
}
}

@media (max-width: 991.98px){
  .sidebar {
    width: 86px;
    overflow: auto;
  }
  html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 86px;
}
.myProjects{
  left: 86px;
}
.app-body{
  margin-top: 84px;
}
.app-header .navbar-brand{
  left: 0px;
  margin-left: 30px;
  width: 30px;
  justify-content: flex-start;
  overflow: hidden;
  margin-top: 14px;
}
}
@media (max-width: 767.98px){
  .sidebar {
    width: 86px;
    overflow: auto;
  }
}
.btn.btn-orange {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  text-align: center;
  color: #EDA843;
  background-color: #ffffff;
  border-color: #EDA843;
  box-shadow: none;
  height: 45px;
  padding-left: 27px;
  padding-right: 27px;

  text-transform: capitalize;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #EDA843;
    border-color: #EDA843;
  }
}
.mlTaskComplete{
  padding-left: 38px;
}
.mlTask{
  padding-left: 27px;
}
.taskTable{
  .MuiTableCell-root{
    padding-left: 53px!important;
  }
}
.tasklistWrap{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
      .lableName{
        font-size: 17px;
        // line-height: 20px;
        color: #000000;
        padding-left: 53px;
        padding-right: 53px;
        margin-bottom: 27px;
        // margin-top: 30px;
      }
      .row{
        padding-left: 58px;
        padding-right: 53px;
        margin-bottom: 27px;
        .MuiFormControlLabel-label{
          font-size: 17px;
          font-weight: bold;
          color: #000;
        }
        .MuiFormControlLabel-root{
          margin-bottom: 0px;
        }
        .time{
          font-size: 17px;
          line-height: 20px;
          color: #828282;
          padding-top: 9px;
          font-weight: 400;
        }
        &:hover{
          background-color: #F2F2F2;
        }
      }
}

.taskButtonWrap{
  text-align: center;
  .btn{
    font-weight: 600;
    font-size: 14px;
    line-height: 92.58%;
    display: inline-block;
    color: #00647E;
    background-color: #ffffff;
    border-color: #00647E;
    box-shadow: none;
    height: 45px;
    min-width: 102px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    cursor: pointer;
    margin-right: 35px;
    position: relative;
    span{
      position: absolute;
      right: -9px;
      top:0;
      font-size: 11px;
      border-radius: 10px;
      font-weight: normal;
      height: 19px;
      min-width: 19px;
      text-align: center;
      line-height: 19px;
      padding-left: 3px;
      padding-right: 3px;
    }
    .blueBadge{
      background-color: #2D9CDB;
      color: #fff;
    }
    .orangeBadge{
      background-color: #EDA843;
      color: #fff;
    }
    .grayBadge{
      background-color: #C4C4C4;
      color: #fff;
    }
    .redBadge{
      background-color: #EB5757;
      color: #fff;
    }
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: #00647E;
      border-color: #00647E;
    }
  }
  .btn:not(:disabled):not(.disabled):active:focus,
  .btn:not(:disabled):not(.disabled).active:focus,
  .show > .btn.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #00647E;
  }
  
  .btn:not(:disabled):not(.disabled):active,
  .btn:not(:disabled):not(.disabled).active,
  .show > .btn.dropdown-toggle {
    color: #fff;
    background-color: #00647E;
    border-color: #00647E;
  }
}
.leftMenuSlide{
  position: absolute;
  left:18px;
  top:55px;
}
.topFit{
  //top:174px;
  border-left: 1px solid #E5E5E5;
  box-shadow: none;
  width: 373px;
}
.topFitCompleted{
  //top:174px;
  border-left: 1px solid #E5E5E5;
  box-shadow: none;
  width: 490px;
}
// .topFitDummy{
//   width: 495px;
// }
.grayTxt{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(130, 130, 130, 0.87);

}
.taskBtnWrap{
  border-top: 1px solid #ECEDEF;
  border-bottom: 1px solid #ECEDEF;
  padding: 17px;
  margin:0px -0.75rem;
  .row{
    margin-bottom: 0px;
    .delete{
      background: #F4F4F4;
      border: 1px solid #DCDCDC;
      box-sizing: border-box;
      border-radius: 30px;
      box-shadow: none;
      color: #000;
      padding:10px 10px;
      height: 45px;
      width: 45px;
    }
   
    .save{
      background: #00647E;
      border-radius: 30px;
      box-shadow: none;
      padding:12px 15px;
      height: 45px;
      min-width: 100px;
      font-weight: 600;
      color: #fff;
      text-transform: capitalize;
    }
    .save2{
      background: #00647E;
      border:1px solid #304894;
      border-radius: 30px;
      box-shadow: none;
      padding:12px 12px;
      height: 45px;
      font-weight: 600;
      min-width: 90px;
      color: #fff;
      text-transform: capitalize;
    }
    .save2-outline {
      background: transparent;
      border-radius: 30px;
      box-shadow: none;
      border: 1px solid #304894;
      padding: 12px 12px;
      color: #304894;
      height: 45px;
      font-weight: 600;
      min-width: 90px;
      text-transform: capitalize;
  }
  }
}
.activityWrap{
  background: #E8F0FE;
  border-radius: 4px;
  padding: 17px;
  h3{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4F4F4F;
  }
  .bold{
    font-weight: 500;
  }
  .blueLable{
    background: #00647E;
    border-radius: 5px 5px 5px 0px;
    font-size: 13px;
    line-height: 15px;
    color: #F2F2F2;
    display: inline-block;
    padding: 4px 7px 2px 7px;
  }
  .greenLable{
    background: #27AE60;
    border-radius: 5px 5px 5px 0px;
    font-size: 13px;
    line-height: 15px;
    color: #F2F2F2;
    display: inline-block;
    padding: 4px 7px 2px 7px;
  }
}
.documentWrap{
  h3{
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #5F6368;
  margin-bottom: 20px;
  }
  .docList{
    margin: 10px 0px;
    padding:0px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #5F6368;
    svg{
      color: #EB5757;
      fill: #EB5757;
    }
  }
}
.taskFormControl{
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(130, 130, 130, 0.87);
  label + .MuiInput-formControl{
    margin-top: 5px;
  }
  input[type='text']:not(.browser-default):focus:not([readonly]){
    border-bottom: none;
    box-shadow: none;
  }
  div{
    margin-bottom: 10px;
    div{
      margin-bottom: 0px;
      div{
        margin-bottom: 0px;
      }
    }
    FormControlLabel{
      width: 100%;
    }
    .MuiIconButton-label{
      .PrivateRadioButtonIcon-root-44{
        margin-bottom: 0px!important;
      }
    }
    .MuiFormControlLabel-root{
      margin-bottom: 0px;
    }
  }
  .MuiFormControl-marginNormal{
    margin-top: 0px;
    margin-bottom: 0px;
  }
  input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea{
    // height: 2em;
    // margin-bottom: 0px;
    padding:27px 12px 10px;
    height: auto!important;
    border-radius: 4px;
  }
}
// style for replaced anchar button
.buttonAsanchar{
    background: transparent;
    border: none;
    margin: 0px;
    padding: 0px;
    color: rgba(242, 242, 242, 0.6);
}
.devHead{
  margin-top: 1rem !important;
  h2{
    font-size: 14px;
    font-weight: bold;
  }
}
.navline{
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: -15px;
  margin-right: -15px;
}
.online{
  color: #03bf07;
  fill: #03bf07;
  font-size: 1rem!important;
}
.offline{
  color: #ff0000;
  fill: #ff0000;
  font-size: 1rem!important;
}
.squreRadioBtn{
    .btn{
      background: #F4F4F4;
      border: 1px solid #DADCE0;
      box-sizing: border-box;
      border-radius: 3px;
      box-shadow: none;
      padding:10px 10px;
      height: 50px;
      width: 85px;
      color: #4F4F4F;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14);
    }
    .btn.active{
      color: #fff;
      background-color: #1985ac;
      border-color: #187da0;
    }
    .btn.selected{
      color: #000;
      border-color: #2D9CDB;
    }
    .btn.deviation{
      color: #000;
      border-color:#EB5757;
    }
}
.botzeroInput input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea{
  margin-bottom: 0px;
}
.inlineBlock{
  display: inline-block!important;
}
.templateBox{
  color: #fff;
  display: block;
  max-width: 450px;
  left: 90px;
  top: 27px;
  position: absolute;
  @media (max-width: 768px) { 
    max-width: 350px;
   }
  .fileIcon{
    float: left;
    width: 20px;
  }
  .textField{
    min-width: 180px;
    color: #fff;
    border-bottom: 0px!important;
    font-size: 23px;
    max-height: 46px;
    line-height: 1;
    padding-top: 4px;
  }
  .folderIcon{
    float: left;
    width: 20px;
    margin-top: 3px;
  }
}
button:focus{
  outline: none;
  background: none;
}
.createFolderTemp {
  position: absolute;
  z-index: 99;
  width: 365px;
  padding: 15px;
  right: 0;
  top: 0;
  .btnWrap{
    background: #007796;
    padding: 5px;
    color: #fff;
    width: 35x;
    height: 35px;
    float: left;
    cursor: pointer;
  }
  .imputWrp{
    float: left;
    width: 300px;
    line-height: 36px;
    input[type="text"]{
      background: #fff;
      height: 35px!important;
      margin-bottom: 0px;
      border-bottom: 0px;
      padding: 0px 10px;
      color: #333333;
    }
    input[type="text"]::placeholder{
      color: rgba(0, 0, 0, 0.42);
    }
    input:not([type]):focus:not([readonly]), input[type='text']:not(.browser-default):focus:not([readonly]), input[type='password']:not(.browser-default):focus:not([readonly]), input[type='email']:not(.browser-default):focus:not([readonly]), input[type='url']:not(.browser-default):focus:not([readonly]), input[type='time']:not(.browser-default):focus:not([readonly]), input[type='date']:not(.browser-default):focus:not([readonly]), input[type='datetime']:not(.browser-default):focus:not([readonly]), input[type='datetime-local']:not(.browser-default):focus:not([readonly]), input[type='tel']:not(.browser-default):focus:not([readonly]), input[type='number']:not(.browser-default):focus:not([readonly]), input[type='search']:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
      border-bottom: 0px solid #00647E;
      box-shadow: none;
    }
    
  }
}
.pointer{
  cursor: pointer;
}
.underlineClass{
  text-decoration: underline;
}
.MuiTableHead-root{
  border-top: red;
}
.accherColor{
  color: #00647E;
}
.removeBorder{
  border-top: red !important;
}
.minHeight{
  min-height: 250px;
  div{
    transform: translate(-50%,-50%);
    top: 50%;
    position: absolute;
    left: 50%;
    white-space: nowrap;
  }
}
.selectedFolder{
  background: rgba(0, 0, 0, 0.05);
}
.folderList{
  padding: 10px 20px;
  &:hover{
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
  &:visited{
    background: rgba(0, 0, 0, 0.05);
  }
}
.folderList.active{
  background: rgba(0, 0, 0, 0.05);
}
.grayIcon{
  color: #ccc;
  fill: #ccc;
}
.centerContenVH{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.templateLogoWrap{
  width: 89px;
  text-align: center;
  margin-top: -10px;
}

.chatHeaderWrap{
  border-bottom: 1px solid #ccc;
  padding: 15px 0px;

  h1 {
    font-size: 20px;
    color: #333;
  }
  .col-auto{
    .MuiSvgIcon-root{
      font-size: 14px;
      color: #666;
    }
  }
  .MuiAvatar-colorDefault{
    display: inline-flex;
    margin-left: 10px;
    cursor: pointer;
  }
}
.borRight{
  // border-right: 1px solid #ccc;
}
.rowZero{
  .row{
    margin-bottom: 0px!important;
    // align-items: center;
  }
}
.customRadio{
  cursor: pointer;
  span{
    background: #FFFFFF;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 110px;
    width: 30px;
    height: 30px;
    display: inline-flex;
    margin-bottom: -7px;
    cursor: pointer;
    &:hover{
      background: #6FCF97 url(../assets/img/customRadiotick.svg) no-repeat center center;
    }
  }
  span.active{
    background: #6FCF97 url(../assets/img/customRadiotick.svg) no-repeat center center;
  }
  h3{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #333333;
    margin-left: 17px;
    display: inline-flex;
  }
}
.backBgremove div:first-child{
  background:transparent!important;
}


/*======= css for switch button=======*/

@mixin switch-size($width, $height, $font-size, $handle-margin) {
    width: $width;
    height: $height;
  
    .switch-label {
      font-size: $font-size;
    }
  
    .switch-handle {
      width: $height - $handle-margin * 2;
      height: $height - $handle-margin * 2;
    }
  
    .switch-input:checked ~ .switch-handle {
      left: $width - $height + $handle-margin;
    }
  }
  
  @mixin switch($type, $width, $height, $font-size, $handle-margin) {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: $width;
    height: $height;
    background-color: transparent;
    cursor: pointer;
  
    .switch-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  
    .switch-label {
      position: relative;
      display: block;
      height: inherit;
      @if $type == icon {
        font-family: FontAwesome;
      }
      font-size: $font-size;
      font-weight: 600;
      text-transform: uppercase;
      @if $type == ddd {
        background-color: $gray-100;
      } @else {
        background-color: #fff;
      }
      border: 1px solid $border-color;
      border-radius: 2px;
      transition: opacity background .15s ease-out;
    }
    @if $type == text or $type == icon {
      .switch-label::before,
      .switch-label::after {
        position: absolute;
        top: 50%;
        width: 50%;
        margin-top: -.5em;
        line-height: 1;
        text-align: center;
        transition: inherit;
      }
      .switch-label::before {
        right: 1px;
        color: $gray-200;
        content: attr(data-off);
      }
      .switch-label::after {
        left: 1px;
        color: #fff;
        content: attr(data-on);
        opacity: 0;
      }
    }
    .switch-input:checked ~ .switch-label {
      //background: $gray-lightest;
    }
    .switch-input:checked ~ .switch-label::before {
      opacity: 0;
    }
    .switch-input:checked ~ .switch-label::after {
      opacity: 1;
    }
  
    .switch-handle {
      position: absolute;
      top: $handle-margin;
      left: $handle-margin;
      width: $height - $handle-margin * 2;
      height: $height - $handle-margin * 2;
      background: #fff;
      border: 1px solid $border-color;
      border-radius: 1px;
      transition: left .15s ease-out;
      @if $type == ddd {
        border: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
      }
    }
  
    .switch-input:checked ~ .switch-handle {
      left: $width - $height + $handle-margin;
    }
  
  
    @if $type == ddd {
      @extend .switch-pill;
    }
  
    //size variations
    @if $type == default {
  
      &.switch-lg {
        @include switch-size($switch-lg-width, $switch-lg-height, $switch-lg-font-size, $handle-margin);
      }
      &.switch-sm {
        @include switch-size($switch-sm-width, $switch-sm-height, $switch-sm-font-size, $handle-margin);
      }
      &.switch-xs {
        @include switch-size($switch-xs-width, $switch-xs-height, $switch-xs-font-size, $handle-margin);
      }
  
    } @else if $type == text {
  
      &.switch-lg {
        @include switch-size($switch-text-lg-width, $switch-text-lg-height, $switch-text-lg-font-size, $handle-margin);
      }
      &.switch-sm {
        @include switch-size($switch-text-sm-width, $switch-text-sm-height, $switch-text-sm-font-size, $handle-margin);
      }
      &.switch-xs {
        @include switch-size($switch-text-xs-width, $switch-text-xs-height, $switch-text-xs-font-size, $handle-margin);
      }
  
    } @else if $type == icon {
  
      &.switch-lg {
        @include switch-size($switch-icon-lg-width, $switch-icon-lg-height, $switch-icon-lg-font-size, $handle-margin);
      }
      &.switch-sm {
        @include switch-size($switch-icon-sm-width, $switch-icon-sm-height, $switch-icon-sm-font-size, $handle-margin);
      }
      &.switch-xs {
        @include switch-size($switch-icon-xs-width, $switch-icon-xs-height, $switch-icon-xs-font-size, $handle-margin);
      }
  
    } @else if $type == ddd {
  
      &.switch-lg {
        @include switch-size($switch-lg-width, $switch-lg-height, $switch-lg-font-size, 0);
      }
      &.switch-sm {
        @include switch-size($switch-sm-width, $switch-sm-height, $switch-sm-font-size, 0);
      }
      &.switch-xs {
        @include switch-size($switch-xs-width, $switch-xs-height, $switch-xs-font-size, 0);
      }
    }
  }
  
  @mixin switch-variant($color) {
    > .switch-input:checked ~ .switch-label {
      background: $color !important;
      border-color: darken($color,10%);
    }
  
    > .switch-input:checked ~ .switch-handle {
      border-color: darken($color,10%);
    }
  }
  
  @mixin switch-outline-variant($color) {
    > .switch-input:checked ~ .switch-label {
      background: #fff !important;
      border-color: $color;
  
      &::after {
        color: $color;
      }
    }
  
    > .switch-input:checked ~ .switch-handle {
      border-color: $color;
    }
  }
  
  @mixin switch-outline-alt-variant($color) {
    > .switch-input:checked ~ .switch-label {
      background: #fff !important;
      border-color: $color;
  
      &::after {
        color: $color;
      }
    }
  
    > .switch-input:checked ~ .switch-handle {
      background: $color !important;
      border-color: $color;
    }
  }
  
  $switch-lg-width: 48px;
  $switch-lg-height: 28px;
  $switch-lg-font-size: 12px;
  
  $switch-width: 40px;
  $switch-height: 24px;
  $switch-font-size: 10px;
  
  $handle-margin: 2px;
  
  $switch-sm-width: 32px;
  $switch-sm-height: 20px;
  $switch-sm-font-size: 8px;
  
  $switch-xs-width: 24px;
  $switch-xs-height: 16px;
  $switch-xs-font-size: 7px;
  
  
  $switch-text-lg-width: 56px;
  $switch-text-lg-height: 28px;
  $switch-text-lg-font-size: 12px;
  
  $switch-text-width: 48px;
  $switch-text-height: 24px;
  $switch-text-font-size: 10px;
  
  $switch-text-sm-width: 40px;
  $switch-text-sm-height: 20px;
  $switch-text-sm-font-size: 8px;
  
  $switch-text-xs-width: 32px;
  $switch-text-xs-height: 16px;
  $switch-text-xs-font-size: 7px;
  
  
  $switch-icon-lg-width: 56px;
  $switch-icon-lg-height: 28px;
  $switch-icon-lg-font-size: 12px;
  
  $switch-icon-width: 48px;
  $switch-icon-height: 24px;
  $switch-icon-font-size: 10px;
  
  $switch-icon-sm-width: 40px;
  $switch-icon-sm-height: 20px;
  $switch-icon-sm-font-size: 8px;
  
  $switch-icon-xs-width: 32px;
  $switch-icon-xs-height: 16px;
  $switch-icon-xs-font-size: 7px;
  
  .switch.switch-default {
    @include switch('default', $switch-width, $switch-height, $switch-font-size, $handle-margin);
  }
  
  .switch.switch-text {
    @include switch('text', $switch-text-width, $switch-text-height, $switch-text-font-size, $handle-margin);
  }
  
  .switch.switch-icon {
    @include switch('icon', $switch-icon-width, $switch-icon-height, $switch-icon-font-size, $handle-margin);
  }
  
  .switch.switch-3d {
    @include switch('ddd', $switch-width, $switch-height, $switch-font-size, 0);
  }
  
  //pills style
  .switch-pill {
    .switch-label,
    .switch-handle {
      border-radius: 50em !important;
    }
  
    .switch-label::before {
      right: 2px !important;
    }
    .switch-label::after {
      left: 2px !important;
    }
  }
  
  @each $color, $value in $theme-colors {
    //normal style
    .switch-#{$color} {
      @include switch-variant($value);
    }
    //outline style
    .switch-#{$color}-outline {
      @include switch-outline-variant($value);
    }
    //outline alternative style
    .switch-#{$color}-outline-alt {
      @include switch-outline-alt-variant($value);
    }
  }
  /*======================================*/
  .switchBtn{
    [type="checkbox"]+span:not(.lever):before{
      border:0px dotted #03bf07;
    }
    .switch-label{
      width: 30px;
      [type="checkbox"]+span:not(.lever){
        padding-left: 30px;
      }
    }
    span.switch-label{
      padding-left: 30px;

    }
    .switch.switch-default{
      height: 16px;
      width: 30px;
      margin-bottom: 0px;
      .switch-label{
        background: #ccc;
      }
    }
    .switch.switch-default .switch-handle{
      top:0;
      left:1px;
      width: 15px;
      height: 15px;
      
    }
  }


  .SortableItem input:not([type]), 
  .SortableItem input[type=text]:not(.browser-default), 
  .SortableItem input[type=password]:not(.browser-default), 
  .SortableItem input[type=email]:not(.browser-default), 
  .SortableItem input[type=url]:not(.browser-default), 
  .SortableItem input[type=time]:not(.browser-default), 
  .SortableItem input[type=date]:not(.browser-default), 
  .SortableItem input[type=datetime]:not(.browser-default), 
  .SortableItem input[type=datetime-local]:not(.browser-default), 
  .SortableItem input[type=tel]:not(.browser-default), 
  .SortableItem input[type=number]:not(.browser-default), 
  .SortableItem input[type=search]:not(.browser-default), 
  .SortableItem textarea.materialize-textarea {
    //height: 0px;
    border:0px;
  }
  .borLine{
    border-top:0px solid #BDBDBD;
    padding-top: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
  .borLineDescription{

    padding-top: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
  .scrollremove{
    overflow-x: visible;
  }
  .editLane{
    width: 300px;
    border:1px solid #ccc;
  }
  .circleBtn{
    border: 1px solid #007796;
    box-sizing: border-box;
    border-radius: 50%;
    padding: 9px 0px;
    width: 40px;
    height: 40px;
  }
  .subHeadTxt{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
    padding: 20px 30px;
  }
  .thermTile{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    width: 176px;
    height: 278px;
    hr{
      margin-top:0px;
      margin-bottom: 0px;
    }
    .level1{
      padding: 12px 0px;
      .bold{
        font-size: 14px;
        line-height: 15px;
        font-weight: bold;
        color:#333333;
        min-height: 35px;
      }
      .regl{
        font-size: 12px;
        line-height: 15px;
      }
      h1{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 54px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 20px;
      }
      h1.orangetxt{
        color: #EB5757;
      }
      h1.greentxt{
        color: #219653;
      }
      h1.bluetxt{
        color: #00647E;
      }
      .regl2{
        font-size: 12px;
        line-height: 15px;
      }
    }
    .level3{
      padding: 13px 7px;
      .bold{
        font-size: 14px;
        line-height: 15px;
        font-weight: bold;
        color:#333333;
      }
      .reg{
        font-size: 12px;
        line-height: 15px;
      }
    }
    .level4{
      padding: 7px;
      .bold{
        font-size: 12px;
        line-height: 18px;
        font-weight: bold;
        color:#333333;
      }
      .regl{
        font-size: 13px;
        line-height: 15px;
        color: #828282;
      }
    }
  }
  .thermtBtn{
    .btn.btn-orange {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #EDA843;
      background-color: #ffffff;
      border-color: #EDA843;
      box-shadow: none;
      height: 40px;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      text-transform: capitalize;
      border-radius: 40px;
      cursor: pointer;
      &:hover,
      &:active,
      &:focus {
        color: #fff;
        background-color: #EDA843;
        border-color: #EDA843;
      }
    }
    .btn.btn-red {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 14px;
      align-items: center;
      text-align: center;
      color: #EB5757;
      background-color: #ffffff;
      border-color: #EB5757;
      box-shadow: none;
      height: 40px;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      text-transform: capitalize;
      border-radius: 40px;
      cursor: pointer;
      &:hover,
      &:active,
      &:focus {
        color: #fff;
        background-color: #EB5757;
        border-color: #EB5757;
      }
    }
  }
  .roundBtn{
      background: #00647E;
      border-radius: 100px;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
      color: #F2F2F2;
      padding: 10px 20px;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14);
      height: 40px;
  }

  .fieldSize{
    .MuiOutlinedInput-input{
        padding: 12px 10px;
        height: 18px;
        background:#fff;
        em{
            font-size: 14px;
        }
    }
    .MuiInputLabel-outlined{
        transform: translate(10px, 13px) scale(1);
        font-size: 14px;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink{
        color: #00adef;
        background:#f2f2f2;
    }  
}
// .fieldSizeNormal{
//   .MuiOutlinedInput-input{
//       padding: 8px 10px;
//       height: 23px;
//       background:#fff;
//       em{
//           font-size: 14px;
//       }
//   }
//   .MuiInputLabel-formControl{
//     transform: translate(0, 18px) scale(1);
//   }
//   .MuiInputLabel-shrink{
//       transform: translate(0px, 3px) scale(1);
//       font-size: 10px;
//   }
//   .MuiInputLabel-shrink.MuiInputLabel-shrink{
//       color: #00647E;
//   }  
//   input[type='text']:not(.browser-default){
//     height: 24px!important;
//   }

//   label + .MuiInput-formControl{
//     margin-top:12px;
//   }
// }
.roleDiv{
    width: 520px;
    position: fixed;
    top:100px;
    left: 50%;
    transform: translate(-50%, -10px);
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 0 10px #666;
    border-radius: 4px;
    li{
      padding: 10px;
      font-size: 17px;
      &:nth-child(even){
        background:#f7f7f7;
      }
    }
    button{
      border-radius: 20px;
      display: inline-block;
      padding: 7px 15px 7px 15px;
      margin-left: 10px;
      height: 35px;
      background:#00647E;
      border:none;
      color: #fff;
    }
}
.chipsOut{
  border:1px solid #ccc;
  padding: 2px 10px;
  border-radius: 20px;
  margin-bottom: 5px;
  margin-right: 3px;
  display: inline-block;
  .MuiSvgIcon-root{
    font-size: 13px;
    margin-left:5px;
    margin-top:-2px;
    cursor: pointer;
  }
}
.pointer{
  cursor: pointer;
}
.underline{
  text-decoration: underline;
}
.clipBoard{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 5px;
  background-color: #00647E;
  color: #fff;
}
.btn:hover, .btn-large:hover, .btn-small:hover{
  background:#00647E;
  color:#fff;
}
.bgGraySet{
  background:#f2f2f2;
  .innerPad{
    padding: 20px 5px;
  }
}
.bgWhiteSet{
  background:#fff;
  .innerPad{
    padding: 20px 5px;
  }
}
TableContainer{
  min-height: 600px;
}
.descTextfield{
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  resize: none;
  background: #fff;
}
.lblBelo{
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
}
.descTextfieldInner textarea{
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  resize: none;
  &:focus{
    outline: -webkit-focus-ring-color auto 1px;
    box-shadow: none;
  }
}
// .subTaskList{
//   margin-bottom: 0px;
//   padding-bottom: 0px;
//   border-left: 1px dashed #ccc;
//   margin-left: 100px;
// }
// .subTaskList li{
//   background: url(../assets/img/LineHori.png) no-repeat center left;
//   font-size: 14px;
//   color: #5F6368;
//   padding: 5px 0px 5px 49px;
    // &:last-child {
    //   &:before {
    //     border-left:none;
    //   }
    // }
// }




.subTaskList{
 
.b-list { 
  list-style:none;
  //margin:0;
  padding:0;
  padding-left:40px;
  padding-top: 15px;
  //margin-left: 103px;
  &_type_tree {
    .b-list_type_tree { 
      display:none;
    }
    .b-list__item {
      position:relative;
      display:inline-block;
      width:100%;
      color:#5F6368;
      font-size: 14px;
      line-height:1.75;
      cursor:pointer;
      .b-label{
        margin-left: 10px;
      }
      &:before {
        content:"";
        position:absolute;
        border-top:1px dashed #5F6368;
        border-left:1px dashed #5F6368;
        width:35px;
        height:100%; 
        left:-35px;
        top:14px;
      }
      &:after {
        content:"";
        position:absolute;
        border-left:1px dashed #5F6368;
        height:100%;
        left:-35px;
        top:-62px;
        z-index:1;
      }
      &:last-child {
        &:before {
          border-left:none;
        }
      }
    }
    .b-checkbox {
      position:absolute;
      top:-99999px;
      &:checked {
        + .b-list_type_tree {
          display:block;
        }
      }
    }
  }
}
}
.dockList{
  div{
    padding: 10px 15px;
  }
  div:nth-child(odd){
    background: #f7f7f7;

  }
}
.add-doc-btn{
border: 1px solid #F2994A;
background: #fff;
box-sizing: border-box;
border-radius: 100px;
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 92.58%;
color: #F2994A;
box-shadow: none;
&:hover{
  background: #F2994A;
  color: #fff;
  box-shadow: none;
}
}
.horizonLine{
  border-bottom: 1px solid #BDBDBD;
}
.btn, .btn-large, .btn-small, .btn-flat{
  text-transform: unset!important;
}
.fontDoc{
  color: #828282;
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
}

.templateBoxFix{
  color: #fff;
  display: block;
  max-width: calc(100% - 386px);
  left: 90px;
  top: 27px;
  position: absolute;
 
  .fileIcon{
    position: absolute;
    left: 0px;
    width: 20px;
  }
  .textField{
    // min-width: 180px;
    color: #fff;
    border-bottom: 0px!important;
    font-size: 23px;
    max-height: 46px;
    line-height: 1;
    padding-top: 4px;
    padding-left: 39px;
    padding-right: 27px;
    word-break: break-all;
  }
  .folderIcon{
    position: absolute;
    right: 0px;
    z-index: 1;
    cursor: pointer;
    width: 20px;
    margin-top: 7px;
  }
}
.ancherDropdown{
  cursor: pointer;
  color:#007796;
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}
.rgtClickPopuP img{
  width: 17px;
}
.MuiTableRow-root{
  cursor: pointer;
}
.leftPanelCol{
  width: 244px;
  padding-right: 20px!important;
}
.rightPanelCol{
  width: calc(100% - 244px);
  padding-left: 0px!important;
}
.tableIconWrap{
  position: relative;
  min-width: 200px;
  .IconTableWrap{
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(-50%, -50%);
    width: 30px;
  }
  .iconTableText{
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0%,-50%);
    width: 100%;
  }
}

div.googleTable{
  white-space: nowrap; 
  width: 400px; 
  overflow: hidden;
  text-overflow: ellipsis; 
  box-sizing: border-box;
  position: relative;
  align-items: center;
  flex: 1 1 auto;
  padding-left: 0;
  margin-left: -21px;
  @media (max-width: 1900.98px) {
    width: 400px; 
   }
  @media (max-width: 1199.98px) {
    width: 250px; 
   }
  @media (max-width: 991.98px) { 
    max-width: 150px; 
   }
}

div.projectName{
  white-space: nowrap; 
  width: 200px; 
  overflow: hidden;
  text-overflow: ellipsis; 
  box-sizing: border-box;
  position: relative;
  align-items: center;
  flex: 1 1 auto;
  padding-left: 0;
  margin-left: -21px;
  @media (max-width: 1900.98px) {
    width: 200px; 
   }
  @media (max-width: 1199.98px) {
    width: 150px; 
   }
  @media (max-width: 991.98px) { 
    max-width: 75px; 
   }
}

div.documentTable{
  white-space: nowrap; 
 // width: 300px; 
  overflow: hidden;
  text-overflow: ellipsis; 
  box-sizing: border-box;
  position: relative;
  align-items: center;
  flex: 1 1 auto;
  padding-left: 0;
  margin-left: -21px;
  @media (max-width: 1900.98px) {
   // width: 300px; 
   }
  @media (max-width: 1199.98px) {
    //width: 250px; 
   }
  @media (max-width: 991.98px) { 
   // max-width: 150px; 
   }
}
.tempDropResize{
  .MuiFormControl-root{
    background: #fff;
    .MuiOutlinedInput-input{
      padding: 13px 25px 13px 10px;
      background: #fff;
    }
  }
}

.metIconDropdown{
  .MuiSelect-outlined.MuiSelect-outlined{
    padding-left: 40px;
  }
  .MuiInputLabel-outlined{
    transform: translate(44px, 20px) scale(1);
  }
}
.companiesDropdown {
  .MuiSelect-outlined.MuiSelect-outlined{
    background: url(../assets/img/ChooseCompany.svg) no-repeat 15px;
  }
}

.peopleProjectDropdown {
  .MuiSelect-outlined.MuiSelect-outlined{
    background: url(../assets/img/peopleIcon.svg) no-repeat 15px;
  }
}
.documentsDropdown {
  .MuiSelect-outlined.MuiSelect-outlined{
    background: url(../assets/img/Ducumets.svg) no-repeat 15px;
  }
}
.OrganisationDropdown {
  .MuiSelect-outlined.MuiSelect-outlined{
    background: url(../assets/img/chooseOrgenisation.svg) no-repeat 15px;
  }
}
.OrganisationName {
  .MuiSelect-outlined.MuiSelect-outlined{
    background: url(../assets/img/chooseProject.svg) no-repeat 15px;
  }
}
.thirmItDropdown {
  .MuiSelect-outlined.MuiSelect-outlined{
    background: url(../assets/img/taskUnit.svg) no-repeat 15px;
    padding-left: 40px;
  }
  .MuiInputLabel-outlined{
    padding-left: 30px;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink{
    transform: translate(-9px, -6px) scale(0.75);
  }
}
.projectAssociateList{
  ul{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    li{
      background: url(../assets/img/myproject-icon.svg) no-repeat left 5px;
      padding: 5px 5px 5px 35px;
    }
  }
}
.projectAssociateListCopy{
  ul{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    li{
     
      padding: 5px 5px 5px 35px;
    }
  }
}
.highlightField{
  color: #EB5757!important;
}
.linkClass{
  color: #333333;
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}

.errorGlobal{
  color: red;
}
.filledControl{
  .MuiFilledInput-input{
    padding: 27px 12px 10px!important;
  }
  input[type='text']:not(.browser-default){
    height: auto !important;
  }
}
.subHeadForm{
  font-size: 17px;
  margin-bottom: 17px;
  font-weight: 500;
  color: #333;
}
.project-setting-switch .MuiSwitch-thumb{
  padding-left: 25px!important;
  margin-top:-3px;
}
.project-setting-switch .MuiSwitch-thumb:before{
  display: none;

}
.thrmTBtn{
  border: 1px solid #007796;
  box-sizing: border-box;
  border-radius: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #007796;
  display: inline-block;
  padding: 15px 24px;
}
.profile-img-avatar{
  width: 98px;
  height: 98px;
  border-radius: 100px;
  object-fit: cover;
}
.profileremoveBtn{
  position: absolute;
  bottom: 0px;
  color: #fff;
  cursor: pointer;
  background: #ff0000;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 10px;
  left: 50%;
  transform: translate(-50%, 0px);
}
.changeProfile{
  cursor: pointer;
}
.strongText{
  font-weight: bold;
}
.dropArea{
  box-shadow: 0 0 5px #ccc;
  padding: 5px;
}
.app-header{
  .navbar-nav{
  .cl_avtar_wrap{
    .img-avatar{
      height: 35px;
      width: 35px;
      object-fit: cover;
     }
    }
  }
}
.filledControlArea textarea{
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  padding: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
  opacity: 0.54;
}
.thermtBtnLog{
  .btn.btn-orange-log {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: #EDA843;
    background-color: #ffffff;
    border-color: #EDA843;
    box-shadow: none;
    height: 40px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
    border-radius: 40px;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: #EDA843;
      border-color: #EDA843;
    }
  }
  .btn.btn-red-log {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    align-items: center;
    text-align: center;
    color: #EB5757;
    background-color: #ffffff;
    border-color: #EB5757;
    box-shadow: none;
    height: 40px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
    border-radius: 40px;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: #EB5757;
      border-color: #EB5757;
    }
  }
}
.weight500{
font-weight: 500!important;
}
.tempColor{
  background: #00647E;
  border-radius: 3px;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 1px;
  .bold{
    font-weight: bold;
  }
}
.pk{
  color: #c0c0c0;
}
.spanLine{
  margin-left: 10px;
  margin-right: 10px;
}
.btn.btn-export {
  font-weight: 700;
  font-size: 14px;
  line-height: 92.58%;
  display: block;
  align-items: center;
  text-align: center;
  color: #219653;
  background-color: #ffffff;
  border-color: #219653;
  box-shadow: none;
  width: 143px;
  height: 45px;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  cursor: pointer;
  .btn-add-icon {
    padding: 0 18px 0 13px;
  }
  &:hover,
  &:active,
  &:focus {
    border-color: #219653;
  }
}
.btn.btn-exportAll {
  border-color: #00647E;
  color: #00647E;
  margin-right: 0!important;
}


.btn.btn-filter img {
  background-color: transparent;
  padding-right: 5px;
}

.MuiTableHead-root {
  display: table-header-group;
  border-top: 1px solid #DADCE0;
}
.ttlDesc{
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 15px;
color: #828282;
margin-bottom: 3px;
}
.repeatQuestion{
  width: 240px;
  float: left;
  // min-height: 50px;
}
.daviationBg td{
  background: rgba(235,87,87,0.1);
}
.headingDeviationTab{
  margin-left: 275px;
  width: calc(100% - 275px);
  // width: 50%;
}
.headingDeviationTab th{
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 12px;
  color: #828282;
  line-height: 22px;
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
.headingDeviationTab td{
  padding-top: 10px;
  padding-bottom: 10px;
}
.headingDeviationTab tr td:first-child{
  padding-left: 0px;
}
.headingDeviationTab tr:last-child{
  border-bottom: 0px;
}
.ansDaviation{
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #2D9CDB;
}

.ansThermitDaviation{
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: red;
}
.isCOntrolCantainDeviation{
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #EB5757;
}
.devi_subtask{
  font-size: 12px;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.deviationHead{
  margin-top: 1rem !important;
  h2{
    font-size: 14px;
    font-weight: bold;
    color: #EB5757;
    ;
  }
}
.redLine{
  border-bottom: 1px solid #EB5757;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.grayBoldText{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
}
.customDeviationRadio{
  margin-left: 1rem;
  span{
    background: #FFFFFF;
    border: 1px solid #828282;
    box-sizing: border-box;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    margin-bottom: -7px;
    cursor: pointer;
    &:hover{
      background: #EB5757 url(../assets/img/customRadiotick.svg) no-repeat center center;
    }
  }
  span.active{
    background:url(../assets/img/daviationCheckbox.svg) no-repeat center center;
    border: 2px solid #EB5757;
  }
  h3{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 17px;
    display: inline-flex;
  }
}
.filledField{
  input[type='text']:not(.browser-default){
    padding:27px 12px 10px;
    height: auto!important;
    border-radius: 4px;
  }
  div{
    input[type='text']:not(.browser-default){
    padding:27px 12px 10px;
    height: auto!important;
    border-radius: 4px;
  }
  }
  .MuiFilledInput-underline:before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.42);
    border-radius: 4px;
  }
  .MuiFilledInput-input {
    padding: 27px 12px 10px;
    border-radius: 4px;
    color: #000;
    font-weight: 500;
  }
  .rangeWrap{
    text-align: right;
    font-size: 13px;
    line-height: 15px;
    color: #828282;
    margin-top: 4px;
  }
  textarea{
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 4px;
  }
  textarea.textareahighlight{
    border: 1px solid #EB5757;
    color: #EB5757;
  }
}
.lableText{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #00647E;
  margin-bottom: 10px;
}
.davRed{
  .labelDev{
    color: #EB5757 !important;
  }
}
.BreakLineRed{
  border-top: 1px solid #EB5757;
  transform: rotate(-180deg);
}

.thermitHeading{
  font-family: Roboto;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #333333;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
    width: 70%;
}
.delTask{
  color: #828282;
}
.switchTask{
  margin-top: 10px;
}
.reEditButton{
  background: #00647E;
  border-radius: 30px;
  box-shadow: none;
  padding: 12px 12px;
  height: 45px;
  min-width: 90px;
  color: #fff;
  //text-transform: capitalize;
}
.bottomH2{
  font-size: 14px;
  line-height: 18px;
  color: rgba(130, 130, 130, 0.87);
  border: 1px solid rgba(130, 130, 130, 0.37);
  border-radius: 4px;
  padding: 10px;
  *{
    font-size: 14px;
    line-height: 18px;
    color: rgba(130, 130, 130, 0.87);
  }
}
.deleteBtn{
    background: #00647E !important;
    border-radius: 25px !important;
    box-shadow: none;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #fff !important;
    text-transform: capitalize;
    padding: 0rem 0.75rem;
    border: 0px;
    height: 25px;
}
.subheadBlue{
  h5{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #00647E;
  }
  .heading-3{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    // line-height: 16px;
    color: #00647E;
  }
}
.helpTemp{
  font-size: 12px;
  text-align: right;
}
.ActiveRow{
  background: #e8e8e8 !important;
}
.ActiveRowPadding{
  padding-top:16px;
}
.editThermit{
  font-size: 14px!important;
  width: 14px!important;
  height: 14px!important;
  cursor: pointer;
  margin-left: 5px;
}
.centerDiv{  
  width: 100%;
  text-align: center;

}
.listDaysControlProgram{
  display: inline-flex;
}

.listDaysControlProgram .MuiAvatar-root{
  // width:20px;
  // height:20px;
  // margin-left:5px;
  // font-size: 1rem;
  width: 16px;
    height: 16px;
    margin-left: 5px;
    font-size: 13px;
    border-radius: 0;
    line-height: 15px;
}

.dropArrow{
  position: relative;
  svg{
    position: absolute;
    right: 10px;
    top:18px;
  }
}

.custDrop{
  position: relative;
  svg{
    position: absolute;
    right: 20px;
    top:18px;
  }
}
.projectSettingButtonDisabled{  
  opacity: .65;
  background-color: #DFDFDF !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #9F9F9F !important;
  cursor: default;
}
.searchBlock 
{
  
  .MuiSvgIcon-root{
    color: #828282;
    fill: #828282;
  }
  input{
    border-bottom: 0px!important;
    padding-left: 50px!important;
   // color: #ff0000;
  }

input:not([type]):focus:not([readonly]), input[type='text']:not(.browser-default):focus:not([readonly]), input[type='password']:not(.browser-default):focus:not([readonly]), input[type='email']:not(.browser-default):focus:not([readonly]), input[type='url']:not(.browser-default):focus:not([readonly]), input[type='time']:not(.browser-default):focus:not([readonly]), input[type='date']:not(.browser-default):focus:not([readonly]), input[type='datetime']:not(.browser-default):focus:not([readonly]), input[type='datetime-local']:not(.browser-default):focus:not([readonly]), input[type='tel']:not(.browser-default):focus:not([readonly]), input[type='number']:not(.browser-default):focus:not([readonly]), input[type='search']:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #00647E;
  box-shadow: 0 0px 0 0 #00647E;
}
}
.searchBlockEnlarge 
{
 
  .MuiSvgIcon-root{
    color: #00647E;
    fill: #00647E;
  }
  input{
    border-bottom: 0px!important;
    padding-left: 50px!important;
    color: #00647E;
  }

input:not([type]):focus:not([readonly]), input[type='text']:not(.browser-default):focus:not([readonly]), input[type='password']:not(.browser-default):focus:not([readonly]), input[type='email']:not(.browser-default):focus:not([readonly]), input[type='url']:not(.browser-default):focus:not([readonly]), input[type='time']:not(.browser-default):focus:not([readonly]), input[type='date']:not(.browser-default):focus:not([readonly]), input[type='datetime']:not(.browser-default):focus:not([readonly]), input[type='datetime-local']:not(.browser-default):focus:not([readonly]), input[type='tel']:not(.browser-default):focus:not([readonly]), input[type='number']:not(.browser-default):focus:not([readonly]), input[type='search']:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #00647E;
  box-shadow: 0 0px 0 0 #00647E;
}
}
.btn_confirmDelete,.btn_confirmDelete:active{
  border-radius: 20px;
  background: #00647E;
  display: inline-block;
  padding: 7px 15px;
  margin-left: 10px;
  height: 37px;
  color: #fff;
  border: none;

}
.custom-focus-off:focus{
  box-shadow: none!important;
  background: #00647E!important;
}

.borderClassDelete{
  border: 1px solid #eee;
  padding: 0px 5px;
}

.trashRestoreDelete{
  display: inline-block;
  padding: 10px;
}


.task_specialTask{
  width: 88px !important;
  cursor: pointer;
}

.task_specialTaskPopup{
position: absolute;
top: 60px;
transition: all 200ms;
font-size: 30px;
font-weight: bold;
text-decoration: none;
// color: #333;
// border: 1px solid;
border-radius: 3px;
left: 0;
background: #fff;
    width: 78%;
    box-shadow: 0px 0 25px #ccc;
    padding: 20px 15px;
}

.task_specialtask_list{
  font-size: 16px;
  background: transparent;
  color: white;
  padding: 8px 5px;
  /* margin: 5px 10px 0px 10px; */
  text-align: left;
  font-weight: 400;
  width: 100%;
  color:black;
  cursor: pointer;
}
.cust-list-pop ul{
  margin-bottom: 0!important;
}
.task_specialtask_list:hover{
  background: rgba(0, 0, 0, 0.04);
}
.taskDeleteIcon{
  padding-top: 8px;
  cursor: pointer;
  margin-right: 2px;
  z-index: 1;
  right: 24px;
  position: absolute;
}


#searchPlaceholder::placeholder {
    color: #ff0000;
    opacity: 1;
}

.customSearch{
  background-color: #F2F2F2!important;
  width: 45px!important;
    margin-left: 8px;
    height: 45px;
    border-radius: 50%!important;
}
.serch-custom-input{
  display: block!important;
}

.common-btn-pd{
  padding: 6px 16px!important;
}

.custom-repeat-question{
  display: contents;
}

.clear{
  clear: left;
}

.thermitStatusRed{
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 2;
  border-radius: 50% !important;
}

.thermitStatusGreen{
  background-color: green;
  width: 10px;
  height: 10px;
  border-radius: 2;
  border-radius: 50% !important;
}
.thermitStatusYellow{
  background-color: yellow;
  width: 10px;
  height: 10px;
  border-radius: 2;
  border-radius: 50% !important;
  transform: rotate(
90deg);
    // margin-left: 10px;
    transform-origin: 25% 55%;
    margin-top: -6px;
    position: absolute;
    right: 0;
}

// .btn.btn-add:hover, .task_specialTask:active, .btn.btn-add:focus {
//   color: #fff;
//   background-color: #00647E;
//   border-color: #00647E;
// }
.thermitStatusRed{
  transform: rotate(
90deg);
    // margin-left: 10px;
    transform-origin: 25% 55%;
    margin-top: -6px;
    position: absolute;
    right: 0;
}
.thermitStatusGreen{
  transform: rotate(
    90deg);
        // margin-left: 10px;
        transform-origin: 25% 55%;
        margin-top: -6px;
        position: absolute;
    right: 0;
}

.positionIcon{
  position: absolute;
  right: 0;
  top: 32px;
  color: #fff;
  cursor: pointer;
}
.no-shadow input{
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
  -webkit-background-clip: text;
  color: white !important;
}
.signin_form form input[type=text]:not(.browser-default):focus:not([readonly]){
  box-shadow: none!important;
}
.signin_form form input[type=password]:not(.browser-default):focus:not([readonly]){
  box-shadow: none!important;
}
// #deleteTemplate-label{
//   transform: translate(12px, 8px) scale(1);
//   font-size: 12px;
// }

.stickConfirm{
  position: sticky;
  bottom: 0;
}

.deleteTemplteFieldWidth{
   width:100%
}

.tempDelTask{
  height: auto;
  // overflow-x: hidden;
  padding-right: 30px;
  margin-right: -30px;  
   
}

.retcenData{
  text-align: center;
}
.text-area-custom{
  height: 60px!important;
    overflow: hidden;
    border:none;
}
#mui-rte-root{
  width: 100%;
  min-height: 90px;
  border-radius: 14px !important;
  background-color: #e8f0fe;
}

#mui-rte-toolbar {
  padding: 10px 13px !important;
  // margin: 0px 0px 10px 0px !important;

}

#mui-rte-container .jss9 {
  padding: 0 17px 10px !important;
  background-color: #e8f0fe;
  margin: 0 !important;
  line-height: 9px;
  // padding: 10px 1px 16px 16px !important;
  top: 50px;
  border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}

.MUIRichTextEditor-editorContainer-19 {
  width: 100%;
  cursor: text;
  margin: 0px !important;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  // padding: 7px 0px 8px 17px;
  padding: 0px 0px 8px 17px !important;
  top: 44px;

}

.MUIRichTextEditor-placeHolder-51 {
  color: #757575;
  outline: none;
  // position: absolute;
  background-color: #e8f0fe;
  margin: 0px;
  top: 51px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.MUIRichTextEditor-editorContainer-5,
#mui-rte-editor-container {
  margin: -15px 0px 0px 0px !important;
  padding: 15px 20px 10px 20px !important;
  overflow-y: auto !important;
}

.MUIRichTextEditor-editorContainer-33 {
  padding: 0px 0px 8px 17px !important;
  top: 46px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  background-color: #e8f0fe;
  margin: 0 !important;
}



#mui-5 {
  padding-left: 5px;
}

#mui-rte-toolbar {
  padding: 10px 13px !important;
  /* margin: 0 0 10px!important; */
}

#mui-rte-container .jss9 {
  padding: 0px 13px 10px 13px !important;
  background-color: #e8f0fe;
  margin: 0px !important;
  line-height: 18px;
}

.jss19 {
  width: 100%;
  cursor: text;
  /* margin: 0px 0px 0px 17px; */
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 8px 17px !important;
}

// #mui-rte-editor {
//   background-color: #e8f0fe;
// }

.MUIRichTextEditor-root-1 {
  width: 100%;
  min-height: 40px;
  background-color: #e8f0fe;
}


.MUIRichTextEditor-placeHolder-9 {
  padding: 10px 13px !important;
  background-color: #e8f0fe;
  margin: 0px !important;
  color: #757575;
  outline: none;
  // position: absolute;
}

.MUIRichTextEditor-placeHolder-23 {
  background-color: #e8f0fe;
  padding: 0px 17px !important;
  // padding: 0px 0px 16px 16px;
}

.MUIRichTextEditor-placeHolder-37 {
  // padding: 0px 0px 8px 19px;
}

.MUIRichTextEditor-root-15 {
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
}

// .MUIRichTextEditor-container-2 {
//   // margin: 8px 0px 0px 0px;
//   border-bottom-left-radius: 14px;
//   border-bottom-right-radius: 14px;
//   position: relative;
//   font-size: 1rem;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
// }
.edit_icon_text {
  position: relative;
}
.edit_icon_text svg{
  position: absolute;
  right: 10px;
  top: 30px;
}
.edit_inner_icon{
  position:relative;
}
.edit_inner_icon svg{
  position: absolute;
  right: 10px;

}
.attach_icon{
  position: relative;
  background-color: #e8f0fe;
  padding: 20px;
  border-radius: 14px;
  // right: -20px;
  left: 13px;
  top: 0px;
  transform: rotate(-90deg);
}
.attach_icon svg{
  position: absolute;
  top: 9px;
  right: 8px;
}
// .attach_icon_inner{
//   position:relative;
// }
// .attach_icon_inner svg{
//   position: absolute;
//   top: 30px;
// }
#mui-1{
padding-left:5px;
}
// #mui-rte-editor{
// max-height: 50px;
// overflow-x: auto;
// }
#mui-rte-container.MUIRichTextEditor-container-2, #mui-rte-container{
  margin-top:0px;
}
#mui-rte-toolbar.MUIRichTextEditor-toolbar-13, #mui-rte-toolbar{
  padding: 6px!important;
}

.MUIRichTextEditor-editorContainer-5,
#mui-rte-editor-container {
  margin: -15px 0px 0px 0px !important;
  padding: 17px 20px 10px 20px !important;
  max-height: 120px !important;
  overflow-y: auto !important;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.MUIRichTextEditor-editorContainer-47 {
  width: 100%;
  cursor: text;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 8px 17px !important;
}

.placeholder {
  width: 100%;
  cursor: text;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 8px 17px !important;
}

.MUIRichTextEditor-editorContainer-89 {
  width: 100%;
  cursor: text;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 8px 17px !important;
}

.custom_align {
  margin-bottom: 13px;
}
.aligned_components{
  align-items: center;
  justify-content: center;
}

.MUIRichTextEditor-editorContainer-61 {
  width: 100%;
  cursor: text;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 8px 17px !important;
}

.MUIRichTextEditor-editorContainer-75 {
  width: 100%;
  cursor: text;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 8px 17px !important;
}

#mui-rte-container div:nth-child(2) {
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 8px 17px !important;
}

.no-space-right {
  justify-content: flex-end !important;
}
.no-space-right{
 justify-content: flex-end!important;
 }
.m-nav .active .active_icons{
  color: #007796;
}
.leftPanelCol button{
  margin: 0px 0px 0px 27px!important;
}
.leftPanelCol .m-nav ul li label{
  padding: 0px 0px 0px 27px;
}
.custom_align nav ol li{
  padding-left: 27px;
}
.shift{
  padding-left: 27px;
}
.MuiTableCell-root:first-child{
  padding-left:27px!important;
}
.MuiTableCell-root{
  padding:10px 20px!important;
}
// .MuiTableCell-paddingNone{
//   padding:10px 20px!important;
// }
// .heading-3{
//   padding-left: 27px;
// }
.main .container-fluid.container_wrap{
  padding-right: 27px;
}
.add_pop-rgt .btn-close-icon{
  width: 50px;
}
.subheadBlue .heading-3{
    margin-top: 17px;
    margin-bottom: 30px;
}
.tasklistWrap .row:last-child{
  margin-bottom: 37px;
}
.MuiFilledInput-root {
  border: 1px solid #d1d1d1!important;
  border-radius: 4px!important;
  background-color: transparent!important;
}
.MuiFilledInput-underline:before{
  border-bottom: none!important;
}
.MuiFilledInput-input{
  border-bottom: none!important;
  box-shadow: none!important;
}
.MuiFilledInput-underline:after{
  border-bottom: none!important;
}
.navbar-nav .asst-sidebar-link .pg_counter{
  position: absolute;
    right: 52%;
}
.plr-0{
  padding-left: 27px;
  padding-right: 20px!important;
}
.chat-pd{
  padding:0 27px 0 15px;
}
.complete-task-row{
  padding-left: 27px;
}
.completed-task-heading{
  padding-left: 27px;
}
.right-btn-mr{
  padding-right: 37px;
}
.only-align{
  align-items: center;
}
.border-task{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.round_green{
  width:10px;
  height:10px;
  background-color: #27AE60;
  border-radius: 50%;
}
.round_red{
  width:10px;
  height:10px;
  background-color: #FF0000;
  border-radius: 50%;
}
.control_box{
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-bottom: 15px;
}
.thermit_btn_outline{
  background: transparent!important;
  border-radius: 25px !important;
  box-shadow: none;
  text-align: center;
    color: #00647E !important;
    border: 1px solid #00647E!important;
}
.thermit_btn_outline:hover{
  box-shadow: none;
}
.unlink_btn{
  background: #F4F4F4!important;
  border-radius: 25px !important;
  box-shadow: none;
  text-align: center;
    color: #4F4F4F !important;
    border: 1px solid #DCDCDC!important;
    font-weight: 600;
    letter-spacing: 0;
}
.unlink_btn:hover{
  box-shadow: none;
}
.right_pos{
  float: right;
  position: absolute;
  right: 15px;
}
.title_unlink{
  font-size: 17px;
  padding: 20px 15px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #bdbdbd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #4F4F4F;
  font-weight: 500;
}
.msg_clr{
  color: #00647E;
  font-size: 14px;
  font-weight: bold;
}
.btn_group{
  margin: 2rem 4rem;
}
.btn_unline_light{
  background: transparent!important;
  border-radius: 25px !important;
  box-shadow: none;
  text-align: center;
    color: #00647E !important;
    width: 100%;
    border: 1px solid #00647E!important;
}
.btn_unline_dark{
  background: #00647E!important;
  border-radius: 25px !important;
  box-shadow: none;
  margin-bottom: 1.2rem;
  text-align: center;
  width: 100%;
    color: #fff !important;
    border: 1px solid #00647E!important;
}

.MUIRichTextEditor-hidePlaceholder-8 {
  display: block !important;
}

.btn_unline_dark:hover {
  box-shadow: none;
}


.btn_unline_light:hover {
  box-shadow: none;
}
.btn-close-icon-unlink{
  position: absolute;
  right: 0;
  top: 0;
  padding: 23px 15px 23px 23px;
  cursor: pointer;
  width: 50px;
}
.round_red_small{
  width: 11px;
  height: 11px;
  background-color: #FF0000;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: 3px;
  border: 2px solid #1E3473;
  z-index: 111;
}
.round_green_small{
  width: 11px;
  height: 11px;
  background-color: #27AE60;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: 3px;
  border: 2px solid #1E3473;
  z-index: 111;
}

.paused_date h6{
color: #EDA843;
font-weight: 400;
font-size: 13px;
}
.paused_date h6 span{
  font-weight: 600;
  }
.control_parent{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parent_hover:hover{
  background: #e8e8e8 !important;
}

.logoLogin h3{
  color: #fff;
  font-weight: 700;
  font-size: 21px;
  margin-left: 60px;
  }
.break_txt label{
  column-gap: 2px;
}
.delete_actions{
  padding: 20px 0px 0px!important;
  display: flex;
  justify-content: space-between;
}
.delete_actions .btn_confirmDelete {
  margin: 0!important;
}
.delete_actions .btn_confirmcancel{
  color: #4F4F4F;
  background-color: #F4F4F4;
  border: 1px solid #DCDCDC;
  box-shadow: none;
  margin: 0!important;
}
.icon_search{
  left:11px;
  position: absolute;
}
.searchBlockEnlarge{
  background-color: #F2F2F2;
  border-radius: 76px;
}
.searchBlockEnlarge .customSearch{
  width:300px!important;
  border-radius: 76px!important;
  margin-left: -105px;
}
.searchBlockEnlarge input{
  padding-top: 3px!important;
  width: 75%!important;
}
.searchBlockEnlarge input::placeholder{
  color: #304894!important;
  opacity: 1;
}
.orangetxt .blank_txt{
letter-spacing: -4px;
}
.bold .blank_txt{
  letter-spacing: -3px;
}
.searchBlock{
  float: right;
}
.thermit_icons{
  margin-bottom: 0px!important;
}
.justi-content{
  justify-content: space-between;
}
.thermit_tabs{
  min-height: auto!important;
  padding-left: 50px;
  margin-top: 10px;
}
.grid_head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid_title{
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-bottom: 0rem;
  padding-left: 23px;
}
.page_count{
  font-weight: 400;
  font-size: 13px;
  color: #5F6368;
  margin-bottom: 0rem;
}
.grid_actions{
  display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;  
    margin-right: 23px;
}
.pagination_icon{
  width: 0.6em!important;
  height: 0.6em!important;
  cursor: pointer;
}
.table_grid_head th, .table_grid_head td{
  text-align: center;
}
.table_grid_head th:first-child{
  border-right: 3px solid #DADCE0;
  width: 120px;
}
.table_header{
  border-bottom: 1px solid #DADCE0;
  border-top: 1px solid #DADCE0;
}
.table_grid_head th{
  font-size: 13px;
  color: #5F6368;
  font-weight: 700;
}
.table_grid_head td{
  font-size: 13px;
  color: #5F6368;
  font-weight: 400;
  width: 120px;
}
.thermit_table{
  width: 100%;
  margin-top: 25px;
}
.thermit_table tbody tr:first-child{
  border-bottom: 3px solid #DADCE0;
}
.thermit_table tr:nth-child(even) {
  background-color: rgba(241, 241, 241, 0.3);
}
.thermit_heading{
  font-weight: 500;
  font-size: 20px;
  color: #000000;
}
.tab-red{
  background-color: #ffe6e6;
  color: #FF0000!important;
}
.filter-thermit{
padding-right: 0;
}
.btn.btn-filter {
  border-color: gray;
  color: #4F4F4D;
  margin-right: 0 !important;
  background-color: #f4f4f4;
  font-weight: 700;
  font-size: 14px;
  line-height: 92.58%;
  display: block;
  align-items: center;
  text-align: center;
  box-shadow: none;
  width: 168px;
  height: 45px;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  cursor: pointer;
  margin-left: -9px;
}
.btn.btn-filter:hover {
  border-color: gray;
  color: #4F4F4D;
  margin-right: 0 !important;
  background-color: #f4f4f4;
  font-weight: 700;
  font-size: 14px;
  line-height: 92.58%;
  display: block;
  align-items: center;
  text-align: center;
  box-shadow: none;
  width: 168px;
  height: 45px;
  font-family: 'Poppins', sans-serif;
  text-transform: capitalize;
  cursor: pointer;
  margin-left: -9px;
}

// .table_header tr td{
//   width: 120px;
// }
.date-block {
  margin-bottom: 0 !important;
}

.bottom-pagination {
  justify-content: end;
  margin-top: 1rem;
}

.bottom-actions {
  margin-right: 0;
}

.tab-highlight {
  background-color: #E8F0FE !important;
}

.heading_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0rem;
  padding-left: 23px;
  border-bottom: 1px solid #5F6368;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.selected-filter {
  color: #EDA843;
  margin-bottom: 0;
}
