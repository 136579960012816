// Styles
// CoreUI Icons Set
// eslint-disable-next-line
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
// eslint-disable-next-line
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// eslint-disable-next-line
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
//@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
// eslint-disable-next-line
@import './scss/style.scss';
